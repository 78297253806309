import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Grid,
  Paper,
  Chip,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CategoryIcon from "@mui/icons-material/Category";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ChatIcon from "@mui/icons-material/Chat";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";

const ProductView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [seller, setSeller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [callDialogOpen, setCallDialogOpen] = useState(false);

  useEffect(() => {
    const fetchProductAndSeller = async () => {
      try {
        // Fetch product details
        const productRef = doc(db, "listings", id);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          const productData = { id: productSnap.id, ...productSnap.data() };
          setProduct(productData);

          // Update view count
          await updateDoc(productRef, {
            views: (productData.views || 0) + 1,
          });

          // Fetch seller details
          if (productData.userId) {
            const sellerRef = doc(db, "users", productData.userId);
            const sellerSnap = await getDoc(sellerRef);

            if (sellerSnap.exists()) {
              setSeller(sellerSnap.data());
            }
          }
        } else {
          setError("Listing not found");
        }
      } catch (err) {
        setError("Failed to fetch listing");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProductAndSeller();
  }, [id]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-TZ", {
      style: "currency",
      currency: "TZS",
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handleCallClick = () => {
    setCallDialogOpen(true);
  };

  const handleCallConfirm = () => {
    if (seller?.phoneNumber) {
      window.location.href = `tel:${seller.phoneNumber}`;
    }
    setCallDialogOpen(false);
  };

  const handleCallCancel = () => {
    setCallDialogOpen(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => navigate(-1)}
          startIcon={<ArrowBack />}
        >
          Go Back
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Button
        variant="outlined"
        onClick={() => navigate(-1)}
        startIcon={<ArrowBack />}
        sx={{ mb: 3 }}
      >
        Back
      </Button>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
            <img
              src={product.images?.[0] || "/placeholder-product.jpg"}
              alt={product.title}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/placeholder-product.jpg";
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: "none" }}>
            <CardContent>
              <Typography variant="h4" component="h1" gutterBottom>
                {product.title}
              </Typography>

              <Typography variant="h5" color="primary" gutterBottom>
                {formatPrice(product.price)}
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <LocationOnIcon color="action" sx={{ mr: 1 }} />
                <Typography variant="body1">{product.location}</Typography>
              </Box>

              <Chip
                label={product.category}
                icon={<CategoryIcon />}
                sx={{ mb: 2 }}
              />

              <Divider sx={{ my: 2 }} />

              <Typography variant="h6" gutterBottom>
                Description
              </Typography>
              <Typography variant="body1" paragraph>
                {product.description || "No description provided"}
              </Typography>

              {/* Seller Information Section */}
              <Divider sx={{ my: 3 }} />
              <Typography variant="h6" gutterBottom>
                Seller Information
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Avatar sx={{ mr: 2 }}>
                  {seller?.displayName?.charAt(0) || <PersonIcon />}
                </Avatar>
                <Box>
                  <Typography variant="subtitle1" fontWeight={500}>
                    {seller?.displayName || "Unknown Seller"}
                  </Typography>
                  {seller?.verified && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <VerifiedUserIcon
                        color="primary"
                        fontSize="small"
                        sx={{ mr: 0.5 }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        Verified Seller
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Contact Seller Section */}
              <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={{
                    pathname: `/chat/${product.userId}`,
                    state: {
                      productId: product.id,
                      productTitle: product.title,
                      productImage: product.images?.[0], // Add product image
                    },
                  }}
                  startIcon={<ChatIcon />}
                  sx={{ flex: 1 }}
                >
                  Message
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<PhoneIcon />}
                  onClick={handleCallClick}
                  sx={{ flex: 1 }}
                  disabled={!seller?.phoneNumber}
                >
                  Call Seller
                </Button>
              </Box>

              {/* Dynamic fields based on category */}
              {product.category === "Phones & Tablets" && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Phone Details
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText
                        primary="Brand"
                        secondary={product.brand || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Condition"
                        secondary={product.condition || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Model"
                        secondary={product.model || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Storage"
                        secondary={product.storage || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="RAM"
                        secondary={product.ram || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Color"
                        secondary={product.color || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Network"
                        secondary={product.network || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Battery Health"
                        secondary={
                          product.batteryHealth
                            ? `${product.batteryHealth}%`
                            : "Not specified"
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              )}

              {product.category === "Real Estate" && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Property Details
                  </Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText
                        primary="Size"
                        secondary={product.size || "Not specified"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Bedrooms"
                        secondary={product.bedrooms || "Not specified"}
                      />
                    </ListItem>
                  </List>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Call Confirmation Dialog */}
      <Dialog open={callDialogOpen} onClose={handleCallCancel}>
        <DialogTitle>Call Seller</DialogTitle>
        <DialogContent>
          <Typography>
            {seller?.phoneNumber
              ? `You're about to call ${
                  seller.displayName || "the seller"
                } at ${seller.phoneNumber}`
              : "No phone number available for this seller"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCallCancel}>Cancel</Button>
          <Button
            onClick={handleCallConfirm}
            color="primary"
            variant="contained"
            disabled={!seller?.phoneNumber}
          >
            Call Now
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProductView;
