import React, { useState, useEffect } from 'react';
import { 
  Container, Box, Typography, Button, 
  Paper, Divider, Grid, Chip, 
  IconButton, useMediaQuery, useTheme, 
  CircularProgress, Card, CardContent,
  CardMedia, CardActions, Menu, MenuItem, ListItemIcon
} from '@mui/material';
import { 
  Edit, Delete, Visibility, Archive,
  CheckCircle, PendingActions, Favorite,
  MoreVert, Sell, Refresh, Star,
  Add, FilterList, Sort, Search,
} from '@mui/icons-material';
import { auth, db } from '../config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { styled } from '@mui/material/styles';

// Status constants
const LISTING_STATUS = {
  PENDING_REVIEW: 'pending_review',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

const StatusBadge = styled(Chip)(({ theme, status }) => ({
  borderRadius: '4px',
  fontWeight: 600,
  fontSize: '0.7rem',
  height: '24px',
  ...(status === LISTING_STATUS.ACTIVE && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  }),
  ...(status === LISTING_STATUS.PENDING_REVIEW && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  }),
  ...(status === LISTING_STATUS.ARCHIVED && {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
  }),
}));

const ListingCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'all 0.2s ease',
  borderRadius: '12px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8]
  },
}));

const CardMediaWrapper = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: '56.25%', // 16:9 aspect ratio
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '30%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.5) 0%, transparent 100%)'
  }
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.primary.main,
  fontSize: '1.1rem',
}));

const UserListings = () => {
  const [filter, setFilter] = useState('all');
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchListings = async () => {
      try {
        setLoading(true);
        setError(null);
        
        if (!auth.currentUser) {
          throw new Error('User not authenticated');
        }

        const listingsRef = collection(db, 'listings');
        const q = query(listingsRef, where('userId', '==', auth.currentUser.uid));
        const querySnapshot = await getDocs(q);
        
        const listingsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || null,
          updatedAt: doc.data().updatedAt?.toDate() || null,
          expiresAt: doc.data().expiresAt ? new Date(doc.data().expiresAt) : null,
          isFavorite: doc.data().savedItems?.includes(auth.currentUser.uid) || false
        }));

        setListings(listingsData);
      } catch (err) {
        console.error('Error fetching listings:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchListings();
      } else {
        setLoading(false);
        setError('Please log in to view your listings');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const filteredListings = listings.filter(listing => {
    switch (filter) {
      case 'all': return true;
      case 'pending': return listing.status === LISTING_STATUS.PENDING_REVIEW;
      case 'active': return listing.status === LISTING_STATUS.ACTIVE;
      case 'archived': return listing.status === LISTING_STATUS.ARCHIVED;
      case 'favorites': return listing.isFavorite;
      default: return true;
    }
  });

  const renderListingCard = (listing) => (
    <ListingCard>
      <CardMediaWrapper
        image={listing.images?.[0] || '/placeholder-image.jpg'}
        title={listing.title}
      >
        <Box sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          display: 'flex',
          gap: 1
        }}>
          {listing.isFavorite && (
            <Favorite 
              color="error" 
              sx={{
                fontSize: '1.5rem',
                filter: 'drop-shadow(0 1px 2px rgba(0,0,0,0.3))',
                backgroundColor: 'rgba(255,255,255,0.7)',
                borderRadius: '50%',
                padding: '4px'
              }}
            />
          )}
        </Box>
        <Box sx={{
          position: 'absolute',
          bottom: 8,
          left: 8,
          display: 'flex',
          gap: 1
        }}>
          <StatusBadge 
            label={
              listing.status === LISTING_STATUS.ACTIVE ? 'Published' : 
              listing.status === LISTING_STATUS.PENDING_REVIEW ? 'Pending' : 
              'Archived'
            } 
            status={listing.status}
            size="small"
          />
          {listing.isPromoted && (
            <Chip 
              label="Promoted" 
              size="small" 
              color="primary"
              sx={{ 
                color: 'white', 
                backgroundColor: theme.palette.primary.main,
                fontSize: '0.7rem',
                height: '24px'
              }}
            />
          )}
        </Box>
      </CardMediaWrapper>
      
      <CardContent sx={{ flexGrow: 1, p: 2 }}>
        <Typography gutterBottom variant="h6" component="h3" sx={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          minHeight: '64px',
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: 1.4
        }}>
          {listing.title}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, flexWrap: 'wrap', gap: 0.5 }}>
          <Typography variant="caption" color="text.secondary" sx={{ 
            backgroundColor: theme.palette.grey[100],
            px: 1,
            borderRadius: '4px'
          }}>
            {listing.category}
          </Typography>
          {listing.subcategory && (
            <Typography variant="caption" color="text.secondary" sx={{
              backgroundColor: theme.palette.grey[100],
              px: 1,
              borderRadius: '4px'
            }}>
              {listing.subcategory}
            </Typography>
          )}
        </Box>
        
        <PriceText sx={{ mb: 1 }}>
          Tsh {listing.price?.toLocaleString()}
        </PriceText>
        
        <Typography variant="body2" color="text.secondary" sx={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          minHeight: '40px',
          fontSize: '0.875rem'
        }}>
          {listing.description}
        </Typography>
      </CardContent>
      
      <CardActions sx={{ 
        justifyContent: 'space-between', 
        p: 2,
        pt: 0,
        borderTop: `1px solid ${theme.palette.divider}`
      }}>
        <Button 
          size="small" 
          color="inherit"
          startIcon={<Visibility fontSize="small" />}
          href={`/product/${listing.id}`}
          sx={{
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.primary.main
            }
          }}
        >
          View
        </Button>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            size="small" 
            href={`/edit-listing/${listing.id}`}
            sx={{
              backgroundColor: theme.palette.grey[100],
              '&:hover': {
                backgroundColor: theme.palette.grey[200]
              }
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
          <IconButton 
            size="small"
            sx={{
              backgroundColor: theme.palette.grey[100],
              '&:hover': {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.main
              }
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </CardActions>
    </ListingCard>
  );

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Paper sx={{ 
          p: 4, 
          textAlign: 'center', 
          borderRadius: '12px',
          border: `1px solid ${theme.palette.divider}`
        }}>
          <Typography variant="h6" gutterBottom>
            Error Loading Listings
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            {error}
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            href="/login"
            startIcon={<Refresh />}
          >
            Try Again
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: isMobile ? 2 : 4 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3,
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? 2 : 0
      }}>
        <Box>
          <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ fontWeight: 700 }}>
            My Listings
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {filteredListings.length} {filter === 'all' ? 'total' : filter} listings
          </Typography>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          width: isMobile ? '100%' : 'auto'
        }}>
          {!isMobile ? (
            <>
              <Button
                variant={filter === 'all' ? 'contained' : 'outlined'}
                onClick={() => setFilter('all')}
                startIcon={<Sell />}
                size="small"
              >
                All
              </Button>
              <Button
                variant={filter === 'pending' ? 'contained' : 'outlined'}
                onClick={() => setFilter('pending')}
                startIcon={<PendingActions />}
                size="small"
              >
                Pending
              </Button>
              <Button
                variant={filter === 'active' ? 'contained' : 'outlined'}
                onClick={() => setFilter('active')}
                startIcon={<CheckCircle />}
                size="small"
              >
                Published
              </Button>
              <Button
                variant={filter === 'archived' ? 'contained' : 'outlined'}
                onClick={() => setFilter('archived')}
                startIcon={<Archive />}
                size="small"
              >
                Archived
              </Button>
              <Button
                variant={filter === 'favorites' ? 'contained' : 'outlined'}
                onClick={() => setFilter('favorites')}
                startIcon={<Favorite />}
                size="small"
              >
                Favorites
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={handleMobileMenuOpen}
                startIcon={<FilterList />}
                fullWidth
                size="small"
              >
                Filter
              </Button>
              <Menu
                anchorEl={mobileMenuAnchor}
                open={Boolean(mobileMenuAnchor)}
                onClose={handleMobileMenuClose}
                PaperProps={{
                  sx: {
                    width: 280,
                    maxWidth: '100%',
                    borderRadius: '12px',
                    p: 1
                  }
                }}
              >
                <MenuItem 
                  onClick={() => { setFilter('all'); handleMobileMenuClose(); }}
                  selected={filter === 'all'}
                >
                  <ListItemIcon><Sell fontSize="small" /></ListItemIcon>
                  All Listings
                </MenuItem>
                <MenuItem 
                  onClick={() => { setFilter('pending'); handleMobileMenuClose(); }}
                  selected={filter === 'pending'}
                >
                  <ListItemIcon><PendingActions fontSize="small" /></ListItemIcon>
                  Pending Review
                </MenuItem>
                <MenuItem 
                  onClick={() => { setFilter('active'); handleMobileMenuClose(); }}
                  selected={filter === 'active'}
                >
                  <ListItemIcon><CheckCircle fontSize="small" /></ListItemIcon>
                  Published
                </MenuItem>
                <MenuItem 
                  onClick={() => { setFilter('archived'); handleMobileMenuClose(); }}
                  selected={filter === 'archived'}
                >
                  <ListItemIcon><Archive fontSize="small" /></ListItemIcon>
                  Archived
                </MenuItem>
                <MenuItem 
                  onClick={() => { setFilter('favorites'); handleMobileMenuClose(); }}
                  selected={filter === 'favorites'}
                >
                  <ListItemIcon><Favorite fontSize="small" /></ListItemIcon>
                  Favorites
                </MenuItem>
              </Menu>
            </>
          )}
          
          <Button
            variant="contained"
            color="primary"
            href="/create-listing"
            startIcon={<Add />}
            sx={{ whiteSpace: 'nowrap' }}
            size="small"
          >
            {isMobile ? 'New' : 'New Listing'}
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '200px'
        }}>
          <CircularProgress />
        </Box>
      ) : filteredListings.length === 0 ? (
        <Paper sx={{ 
          p: 4, 
          textAlign: 'center', 
          borderRadius: '12px',
          border: `1px solid ${theme.palette.divider}`
        }}>
          <Typography variant="h6" gutterBottom>
            No listings found
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            {filter === 'all' 
              ? "You haven't created any listings yet." 
              : filter === 'pending' 
                ? "You don't have any listings pending review."
                : filter === 'active'
                  ? "You don't have any published listings."
                  : filter === 'archived'
                    ? "You don't have any archived listings."
                    : "You don't have any favorite listings."}
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            href="/create-listing"
            startIcon={<Add />}
          >
            Create New Listing
          </Button>
        </Paper>
      ) : (
        <Grid container spacing={isMobile ? 1.5 : 3}>
          {filteredListings.map((listing) => (
            <Grid 
              item 
              key={listing.id} 
              xs={12} 
              sm={6} 
              md={4} 
              lg={3}
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {renderListingCard(listing)}
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default UserListings;