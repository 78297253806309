import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
  Link,
  Container,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Chip,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CategoryIcon from "@mui/icons-material/Category";
import WorkIcon from '@mui/icons-material/Work';
import { Link as RouterLink } from "react-router-dom";
import ProductCardWrapper from "../components/ProductCardWrapper";

import {
  collection,
  getDocs,
  query,
  limit,
  where,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useAuth } from "../context/AuthContext";
import HeroImage from "../assets/hero.jpg";

const HomePage = () => {
  const { currentUser } = useAuth();
  const [searchQuery, setSearchQuery] = useState({
    what: "",
    where: "",
  });
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedItems, setSavedItems] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [isSaved, setIsSaved] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Local cities data for Tanzania
  const localCities = [
    { name: "Dar es Salaam"},
    { name: "Mwanza"},
    { name: "Arusha"},
    { name: "Dodoma" },
    { name: "Mbeya"},
    { name: "Morogoro"},
    { name: "Tanga"},
    { name: "Zanzibar"},
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories from Firestore
        const categoriesQuery = query(collection(db, "categories"));
        const categoriesSnapshot = await getDocs(categoriesQuery);
        const categoriesData = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);

        // Fetch listings
        const productsQuery = query(collection(db, "listings"), limit(8));
        const productsSnapshot = await getDocs(productsQuery);
        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
        setFilteredProducts(productsData);

        // Fetch saved items if user is logged in
        if (currentUser) {
          const savedRef = doc(db, "savedItems", currentUser.uid);
          const savedSnap = await getDoc(savedRef);
          if (savedSnap.exists()) {
            setSavedItems(savedSnap.data().items || {});
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    let filtered = [...products]; // Start with the base products (fetched with limit)

    // --- Filter by Selected Category ---
    if (selectedCategory) {
      filtered = filtered.filter((product) => {
        // Ensure the product has a category field to avoid errors
        if (!product.category) return false;

        // Check if the product's category (string name) matches:
        // 1. The selected category's ID (less likely, but for robustness)
        // OR
        // 2. The selected category's Name (case-insensitive)
        return (
          product.category === selectedCategory.id ||
          (selectedCategory.name && product.category.toLowerCase() === selectedCategory.name.toLowerCase())
        );
      });
    }

    // --- Filter by Selected City ---
    if (selectedCity) {
      filtered = filtered.filter((product) =>
        // Use optional chaining and case-insensitive comparison
        product.location?.toLowerCase().includes(selectedCity.name.toLowerCase())
      );
    }

    // --- Filter by Search Query (only if isSearching is true) ---
    if (isSearching) {
      // Filter by "what" (title or description)
      if (searchQuery.what) {
        const whatLower = searchQuery.what.toLowerCase();
        filtered = filtered.filter((product) =>
          // Use optional chaining
          product.title?.toLowerCase().includes(whatLower) ||
          product.description?.toLowerCase().includes(whatLower)
        );
      }
      // Filter by "where" (location)
      if (searchQuery.where) {
        const whereLower = searchQuery.where.toLowerCase();
        filtered = filtered.filter((product) =>
          // Use optional chaining
          product.location?.toLowerCase().includes(whereLower)
        );
      }
    }

    // Update the state with the newly filtered list
    setFilteredProducts(filtered);

  // Dependencies: Re-run this effect if any of these change
  }, [selectedCategory, selectedCity, products, searchQuery, isSearching]);

  const getTimeAgo = (timestamp) => {
    if (!timestamp?.seconds) return 'Date not specified';
  
    const createdDate = new Date(timestamp.seconds * 1000);
    const now = new Date();
    const diffInSeconds = Math.floor((now - createdDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 30 * 86400) return `${Math.floor(diffInSeconds / 86400)} days ago`;
  
    return createdDate.toLocaleDateString(); // fallback for older dates
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchQuery((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setIsSearching(true);
  };

  const handleSaveClick = async (id, e) => {
    e.stopPropagation();
    if (!currentUser) {
      // Optionally redirect to login
      return;
    }

    try {
      const savedRef = doc(db, "savedItems", currentUser.uid);
      const newSavedItems = { ...savedItems };

      if (newSavedItems[id]) {
        delete newSavedItems[id];
      } else {
        newSavedItems[id] = true;
      }

      await setDoc(savedRef, { items: newSavedItems });
      setSavedItems(newSavedItems);
    } catch (error) {
      console.error("Error saving item:", error);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setIsSearching(false);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setIsSearching(false);
  };

  const clearFilters = () => {
    setSelectedCategory(null);
    setSelectedCity(null);
    setIsSearching(false);
    setSearchQuery({ what: "", where: "" });
  };

  const formatPrice = (price) => {
    if (!price) return "Negotiable";
    return new Intl.NumberFormat("en-TZ", {
      style: "currency",
      currency: "TZS",
      maximumFractionDigits: 0,
    }).format(price);
  };

  const getCategoryById = (id) => {
    return categories.find((category) => category.id === id);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate();
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const getJobType = (listingType) => {
    switch (listingType) {
      case "individual":
        return "Individual";
      case "company":
        return "Company";
      default:
        return listingType;
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ overflowX: "hidden" }}>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          py: isMobile ? 8 : 12,
          px: 2,
          textAlign: "center",
          minHeight: isMobile ? "60vh" : "70vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant={isMobile ? "h3" : "h2"}
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              mb: 3,
              textShadow: "0 2px 4px rgba(0,0,0,0.5)",
            }}
          >
            Sell and Buy near you
          </Typography>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            sx={{
              mb: 5,
              textShadow: "0 1px 3px rgba(0,0,0,0.5)",
            }}
          >
            Simple, fast and efficient
          </Typography>

          {/* Search Form */}
          <Box
            component="form"
            onSubmit={handleSearchSubmit}
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              p: 1,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              maxWidth: 800,
              mx: "auto",
              boxShadow: 3,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search what?"
              name="what"
              value={searchQuery.what}
              onChange={handleSearchChange}
              sx={{
                mb: isMobile ? 1 : 0,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Where?"
              name="where"
              value={searchQuery.where}
              onChange={handleSearchChange}
              sx={{
                mb: isMobile ? 1 : 0,
                ml: isMobile ? 0 : 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size={isMobile ? "medium" : "large"}
              sx={{
                ml: isMobile ? 0 : 1,
                px: 4,
                borderRadius: 1,
                textTransform: "none",
                fontWeight: "bold",
                width: isMobile ? "100%" : "auto",
                py: isMobile ? 1.5 : null,
              }}
            >
              Find
            </Button>
          </Box>
        </Container>
      </Box>

      {!isSearching && (
  <Container maxWidth="lg" sx={{ py: isMobile ? 4 : 6 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4,
        flexDirection: isMobile ? 'column' : 'row',
        textAlign: isMobile ? 'center' : 'left',
      }}
    >
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        sx={{ fontWeight: "bold", mb: isMobile ? 2 : 0 }}
      >
        Browse by category
      </Typography>
      <Link
        component={RouterLink}
        to="/categories"
        sx={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          color: "primary.main",
        }}
      >
        View all categories
        <ArrowForwardIcon sx={{ ml: 1 }} />
      </Link>
    </Box>

    {/* Strict 2 columns grid */}
        <Grid container spacing={isMobile ? 2 : 3} sx={{ 
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            md: 'repeat(6, 1fr)'
          },
          gap: isMobile ? 2 : 3
        }}>
      {categories.slice(0, 12).map((category) => {
        return (
          <Grid item xs={6} key={category.id}>
            <Card
              component={RouterLink}
              to={`/category/${encodeURIComponent(category.name)}`}
              onClick={() => handleCategoryClick(category)}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease',
                border: selectedCategory?.id === category.id ? '2px solid' : '1px solid',
                borderColor: selectedCategory?.id === category.id ? 'primary.main' : 'divider',
                borderRadius: 2,
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                textDecoration: 'none',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                  transform: 'translateY(-4px)',
                },
              }}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  textAlign: 'center',
                  p: 2,
                  '&:last-child': { pb: 2 },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 1,
                    color: 'primary.main',
                    height: 40,
                    fontSize: '3rem',
                  }}
                >
                {category.icon}
                </Box>
                <Typography
                  variant="subtitle1"
                  component="h3"
                  sx={{
                    fontWeight: 'medium',
                    mb: 1,
                    minHeight: '3em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {category.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {category.listingsCount || category.count || 0} listings
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  </Container>
)}


      {/* Cities Section - Hidden during search */}
      {!isSearching && (
  <Box
    sx={{
      backgroundColor: "background.paper",
      py: isMobile ? 4 : 6,
      borderTop: "1px solid",
      borderColor: "divider",
    }}
  >
    <Container maxWidth="lg">
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h2"
        sx={{
          fontWeight: "bold",
          mb: 4,
          textAlign: isMobile ? "center" : "left",
        }}
      >
        Popular locations in Tanzania
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)", // 2 columns on mobile
            sm: "repeat(3, 1fr)", // 3 columns on small screens
            md: "repeat(4, 1fr)", // 4 columns on medium screens
            lg: "repeat(5, 1fr)", // 5 columns on large screens
          },
          gap: isMobile ? 2 : 3,
        }}
      >
        {localCities.map((city, index) => (
          <Button
            key={index}
            component={RouterLink}
            to={`/browse-listings`}
            onClick={() => handleCityClick(city)}
            fullWidth
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textTransform: "none",
              color: "text.primary",
              py: 1.5,
              px: 2,
              borderRadius: 2,
              minHeight: "56px", // Consistent height
              borderColor: selectedCity?.name === city.name ? "primary.main" : "divider",
              backgroundColor: selectedCity?.name === city.name ? "action.selected" : "background.paper",
              "&:hover": {
                backgroundColor: "action.hover",
                borderColor: "primary.main",
              },
            }}
          >
            <Typography 
              variant="body1" 
              sx={{ 
                fontWeight: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                mr: 1
              }}
            >
              {city.name}
            </Typography>
            {city.count && (
              <Typography
                variant="body2"
                color="primary.main"
                sx={{
                  fontWeight: "bold",
                  flexShrink: 0,
                }}
              >
                {city.count}
              </Typography>
            )}
          </Button>
        ))}
      </Box>
    </Container>
  </Box>
)}
      {/* Featured Products Section */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{ flexGrow: 1, textAlign: "left" }}
          >
            {isSearching
              ? "Search Results"
              : selectedCategory
              ? `${selectedCategory.name} Listings`
              : selectedCity
              ? `Listings in ${selectedCity.name}`
              : "Recent Listings"}
          </Typography>

          {(selectedCategory || selectedCity || isSearching) && (
            <Button variant="outlined" onClick={clearFilters} sx={{ ml: 2 }}>
              Clear Filters
            </Button>
          )}
        </Box>

        {filteredProducts.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              py: 8,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              No listings found
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {isSearching
                ? "Try adjusting your search criteria"
                : "Check back later for new listings"}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
              },
              gap: isMobile ? 2 : 3,
            }}
          >
           {filteredProducts.map((product) => {
  const category =
    getCategoryById(product.category) ||
    (product.category === "Jobs"
      ? { name: "Jobs", icon: <WorkIcon /> }
      : { name: product.category, icon: <CategoryIcon /> });

  return (
    
<ProductCardWrapper
  product={product}
  isMobile={isMobile}
  savedItems={savedItems}
  handleSaveClick={handleSaveClick}
  category={category}
  getTimeAgo={getTimeAgo}
  formatPrice={formatPrice}
/>

  );
})}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default HomePage;