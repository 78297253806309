import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Badge,
  Divider,
  Chip,
  CircularProgress,
  useTheme,
  Paper,
  IconButton,
  InputBase,
  Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  collection, 
  query, 
  where, 
  onSnapshot, 
  doc, 
  getDoc, 
  orderBy, 
  limit, 
  getDocs,
  updateDoc
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../context/AuthContext';
import { styled, alpha } from '@mui/material/styles';
import { 
  Chat as ChatIcon,
  Search as SearchIcon,
  FiberManualRecord,
  DoneAll
} from '@mui/icons-material';

const ChatListContainer = styled(Container)(({ theme }) => ({
  height: '100vh',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const SearchBar = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(2),
  width: 'auto',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}));

const ChatListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0, 1.5),
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const ChatListPage = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (!currentUser) return;

    const fetchChats = async () => {
      try {
        // Get all chat rooms where the current user is a participant
        const chatsRef = collection(db, 'chats');
        const q = query(chatsRef, where('participants', 'array-contains', currentUser.uid));
        
        const unsubscribe = onSnapshot(q, async (snapshot) => {
          const chatsData = await Promise.all(snapshot.docs.map(async (doc) => {
            const chatData = doc.data();
            const participants = chatData.participants;
            
            // Get the other participant's ID
            const otherUserId = participants.find(id => id !== currentUser.uid);
            if (!otherUserId) return null;
            
            // Get user details
            const userRef = doc(db, 'users', otherUserId);
            const userSnap = await getDoc(userRef);
            if (!userSnap.exists()) return null;
            
            const userData = userSnap.data();
            
            // Get last message
            const messagesRef = collection(db, 'chats', doc.id, 'messages');
            const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'), limit(1));
            const messagesSnap = await getDocs(messagesQuery);
            
            let lastMessage = null;
            let unread = 0;
            
            if (!messagesSnap.empty) {
              lastMessage = messagesSnap.docs[0].data();
              lastMessage.id = messagesSnap.docs[0].id;
              lastMessage.timestamp = lastMessage.timestamp?.toDate();
              
              // Count unread messages
              const unreadQuery = query(
                messagesRef,
                where('senderId', '==', otherUserId),
                where('read', '==', false)
              );
              const unreadSnap = await getDocs(unreadQuery);
              unread = unreadSnap.size;
            }
            
            // Get product details if exists
            let productInfo = null;
            if (chatData.productId) {
              const productRef = doc(db, 'listings', chatData.productId);
              const productSnap = await getDoc(productRef);
              if (productSnap.exists()) {
                productInfo = {
                  id: productSnap.id,
                  title: productSnap.data().title,
                  image: productSnap.data().images?.[0]
                };
              }
            }
            
            return {
              id: doc.id,
              userId: otherUserId,
              user: {
                id: otherUserId,
                name: userData.displayName,
                avatar: userData.photoURL,
                online: userData.isOnline || false
              },
              lastMessage,
              unread,
              product: productInfo,
              updatedAt: chatData.updatedAt?.toDate() || lastMessage?.timestamp
            };
          }));
          
          // Filter out null values and sort by most recent
          const filteredChats = chatsData.filter(chat => chat !== null);
          filteredChats.sort((a, b) => {
            const dateA = a.updatedAt || new Date(0);
            const dateB = b.updatedAt || new Date(0);
            return dateB - dateA;
          });
          
          setChats(filteredChats);
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching chats:', error);
        setLoading(false);
      }
    };

    fetchChats();
  }, [currentUser]);

  const handleChatClick = async (chatId, userId, hasUnread) => {
    if (hasUnread) {
      // Mark messages as read when opening chat
      const messagesRef = collection(db, 'chats', chatId, 'messages');
      const unreadQuery = query(
        messagesRef,
        where('senderId', '==', userId),
        where('read', '==', false)
      );
      
      const unreadSnap = await getDocs(unreadQuery);
      unreadSnap.forEach(async (doc) => {
        await updateDoc(doc.ref, { read: true });
      });
    }
    
    navigate(`/chat/${userId}`);
  };

  const formatTime = (date) => {
    if (!date) return '';
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate = (date) => {
    if (!date) return '';
    
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    if (date.toDateString() === today.toDateString()) {
      return formatTime(date);
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
  };

  const filteredChats = chats.filter(chat => 
    chat.user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (chat.lastMessage?.text && chat.lastMessage.text.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (chat.product?.title && chat.product.title.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  if (loading) {
    return (
      <ChatListContainer maxWidth="sm">
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Messages</Typography>
          {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <Skeleton variant="circular" width={40} height={40} />
                <Box sx={{ ml: 2, flexGrow: 1 }}>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
                <Skeleton variant="text" width={50} />
              </Box>
              <Divider />
            </React.Fragment>
          ))}
        </Box>
      </ChatListContainer>
    );
  }

  return (
    <ChatListContainer maxWidth="sm">
      <Box sx={{ 
        p: 2, 
        borderBottom: `1px solid ${theme.palette.divider}`,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: theme.palette.background.default
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Messages</Typography>
      </Box>
      
      <SearchBar>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search messages..."
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchBar>
      
      {filteredChats.length === 0 ? (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          height: 'calc(100vh - 180px)',
          textAlign: 'center',
          p: 4
        }}>
          <ChatIcon sx={{ 
            fontSize: 60, 
            color: theme.palette.text.secondary, 
            mb: 2 
          }} />
          <Typography variant="h6" gutterBottom>
            {searchQuery ? 'No matching chats' : 'No messages yet'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {searchQuery ? 
              'Try a different search term' : 
              'Start a conversation with sellers to see messages here'}
          </Typography>
        </Box>
      ) : (
        <List sx={{ 
          flex: 1, 
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.action.hover,
            borderRadius: '3px',
          },
        }}>
          {filteredChats.map((chat) => (
            <React.Fragment key={chat.id}>
              <ChatListItem 
                onClick={() => handleChatClick(chat.id, chat.user.id, chat.unread > 0)}
              >
                <ListItemAvatar>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    color={chat.user.online ? 'success' : 'default'}
                    invisible={!chat.user.online}
                  >
                    <Avatar 
                      src={chat.user.avatar} 
                      alt={chat.user.name}
                      sx={{ width: 48, height: 48 }}
                    >
                      {chat.user.name.charAt(0)}
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                
                <ListItemText
                  primary={
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {chat.user.name}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ 
                          fontSize: '0.75rem',
                          whiteSpace: 'nowrap',
                          ml: 1
                        }}
                      >
                        {formatDate(chat.lastMessage?.timestamp)}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 0.5
                    }}>
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                        overflow: 'hidden'
                      }}>
                        {chat.lastMessage?.senderId === currentUser.uid && (
                          <DoneAll 
                            fontSize="small" 
                            sx={{ 
                              mr: 0.5,
                              fontSize: '1rem',
                              color: chat.lastMessage?.read ? 
                                theme.palette.primary.main : 
                                theme.palette.text.disabled
                            }} 
                          />
                        )}
                        
                        <Typography 
                          variant="body2" 
                          color={chat.unread > 0 ? 'text.primary' : 'text.secondary'}
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: chat.unread > 0 ? 500 : 400
                          }}
                        >
                          {chat.lastMessage?.text || 'No messages yet'}
                        </Typography>
                      </Box>
                      
                      {chat.unread > 0 && (
                        <Box sx={{ 
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          borderRadius: '50%',
                          width: 20,
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          ml: 1
                        }}>
                          <Typography variant="caption" sx={{ fontSize: '0.6rem', fontWeight: 600 }}>
                            {chat.unread}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                />
              </ChatListItem>
              
              {chat.product && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  px: 2, 
                  py: 1,
                  mx: 2,
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                  borderRadius: 1
                }}>
                  <Box sx={{ 
                    width: 40, 
                    height: 40, 
                    mr: 1.5,
                    borderRadius: 1,
                    overflow: 'hidden',
                    flexShrink: 0
                  }}>
                    <img 
                      src={chat.product.image} 
                      alt={chat.product.title}
                      style={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover'
                      }}
                    />
                  </Box>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: theme.palette.text.secondary
                    }}
                  >
                    About: {chat.product.title}
                  </Typography>
                </Box>
              )}
              
              <Divider sx={{ mx: 2 }} />
            </React.Fragment>
          ))}
        </List>
      )}
    </ChatListContainer>
  );
};

export default ChatListPage;