import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import Logo from "../assets/logo.png"; 
const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/home"); // Redirect to homepage after 3 seconds
    }, 3000);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: "#fff",
        color: "white",
        textAlign: "center",
      }}
    >
      <img
        src={Logo}
        alt="App Logo"
        style={{ width: 250, marginBottom: 20 }}
      />
      <Typography variant="h5" gutterBottom>
        Welcome to Marketplace
      </Typography>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default SplashScreen;
