import { collection, doc, setDoc, getFirestore } from 'firebase/firestore';
import { db } from "../config/firebase";



const categories = [
    {
        id: 'automobiles',
        name: "Automobiles",
        icon: '🚗',
        subcategories: [
          "Cars",
          "Buses & Minibus",
          "Heavy Equipment",
          "Motorcycles & Scooters",
          "Trucks & Trailers",
          "Vehicle Parts & Accessories",
          "Watercraft & Boats"
        ],
      },
      {
        id: 'phones-tablets',
        name: "Phones & Tablets",
        icon: '📱',
        subcategories: [
          "Mobile Phones",
          "Accessories for Mobile Phones & Tablets",
          "Smart Watches & Trackers",
          "Tablets"
        ],
      },
      {
        id: 'electronics',
        name: "Electronics",
        icon: '💻',
        subcategories: [
          "Accessories & Supplies for Electronics",
          "Laptops & Computers",
          "TV & DVD Equipment",
          "Audio & Music Equipment",
          "Computer Accessories",
          "Computer Hardware",
          "Computer Monitors",
          "Headphones",
          "Networking Products",
          "Photo & Video Cameras",
          "Printers & Scanners",
          "Security & Surveillance",
          "Software",
          "Video Games",
          "Game Consoles"
        ],
      },
      {
        id: 'furniture-appliances',
        name: "Furniture & Appliances",
        icon: '🛋️',
        subcategories: [
          "Furniture - Tableware",
          "Antiques - Art - Decoration",
          "Appliances",
          "Garden",
          "Toys - Games - Figurines",
          "Wine & Gourmet - Recipes"
        ],
      },
      {
        id: 'real-estate',
        name: "Real Estate",
        icon: '🏠',
        subcategories: [
          "Houses & Apartments For Rent",
          "Houses & Apartments For Sale",
          "Land & Plots for Rent",
          "Land & Plots For Sale",
          "Commercial Property For Rent",
          "Commercial Property For Sale",
          "Event centres, Venues and Workstations",
          "Short Rental"
        ],
      },
      {
        id: 'animals-pets',
        name: "Animals & Pets",
        icon: '🐕',
        subcategories: [
          "Birds",
          "Cats & Kittens",
          "Dogs",
          "Fish",
          "Pet's Accessories",
          "Reptiles",
          "Other Animals"
        ],
      },
      {
        id: 'fashion',
        name: "Fashion",
        icon: '👕',
        subcategories: [
          "Bags",
          "Clothing",
          "Clothing Accessories",
          "Jewelry",
          "Shoes",
          "Watches",
          "Wedding Wear & Accessories"
        ],
      },
      {
        id: 'beauty-wellbeing',
        name: "Beauty & Well Being",
        icon: '💄',
        subcategories: [
          "Bath & Body",
          "Fragrance",
          "Hair Beauty",
          "Makeup",
          "Sexual Wellness",
          "Skin Care",
          "Tobacco Accessories",
          "Tools & Accessories",
          "Vitamins & Supplements",
          "Pro Massage"
        ],
      },
      {
        id: 'jobs',
        name: "Jobs",
        icon: '💼',
        subcategories: [
          "Agriculture - Environment",
          "Assistantship - Secretariat - Helpdesk",
          "Automotive - Mechanic",
          "BTP - Construction - Building",
          "Trade - Business Services",
          "Commercial - Sale Jobs",
          "Accounting - Management - Finance",
          "Steering - Manager",
          "Aesthetics - Hair - Beauty",
          "Public Service Jobs",
          "Real Estate Jobs",
          "Independent - Freelance - Telecommuting",
          "Computers - Internet - Telecommunications",
          "Industry, Production & Engineering",
          "Marketing - Communication",
          "Babysitting - Nanny Work",
          "HR - Training - Education",
          "Medical - Healthcare - Social",
          "Security - Guarding",
          "Household Services - Housekeeping",
          "Tourism - Hotels - Restaurants - Leisure",
          "Transportation - Logistics",
          "Other Jobs Offer"
        ],
      },
      {
        id: 'services',
        name: "Services",
        icon: '🔧',
        subcategories: [
          "Casting, Model, Photographer",
          "Carpooling",
          "Moving, Furniture Guard",
          "Destocking - Commercial",
          "Industrial Equipment",
          "Aesthetics, Hairstyling",
          "Materials and Equipment Pro",
          "Event Organization Services",
          "Service Provision",
          "Health, Beauty",
          "Artisan, Troubleshooting, Handyman",
          "Computing Services",
          "Tourism and Travel Services",
          "Translation, Writing",
          "Construction - Renovation - Carpentry",
          "Other Services"
        ],
      },
      {
        id: 'learning',
        name: "Learning",
        icon: '📚',
        subcategories: [
          "Language Classes",
          "Computer Courses",
          "Tutoring, Private Lessons",
          "Vocational Training",
          "Maths, Physics, Chemistry",
          "Music, Theatre, Dance",
          "School Support"
        ],
      },
      {
        id: 'local-events',
        name: "Local Events",
        icon: '🎉',
        subcategories: [
          "Concerts & Festivals",
          "Networking & Meetups",
          "Sports & Outdoors",
          "Trade Shows & Conventions",
          "Training & Seminars",
          "Ceremonies",
          "Conferences",
          "Weddings",
          "Birthdays",
          "Family Events",
          "Nightlife",
          "All Other Events"
        ],
      }
];

const cities = [
    { id: 'arusha', name: 'Arusha' },
    { id: 'babati', name: 'Babati' },
    { id: 'bukoba', name: 'Bukoba' },
    { id: 'bunda', name: 'Bunda' },
    { id: 'dar-es-salaam', name: 'Dar es Salaam' },
    { id: 'dodoma', name: 'Dodoma' },
    { id: 'geita', name: 'Geita' },
    { id: 'iringa', name: 'Iringa' },
    { id: 'kagera', name: 'Kagera' },
    { id: 'kahama', name: 'Kahama' },
    { id: 'kibaha', name: 'Kibaha' },
    { id: 'kilimanjaro', name: 'Kilimanjaro' },
    { id: 'kilosa', name: 'Kilosa' },
    { id: 'kinondoni', name: 'Kinondoni' },
    { id: 'kisarawe', name: 'Kisarawe' },
    { id: 'kigoma', name: 'Kigoma' },
    { id: 'korogwe', name: 'Korogwe' },
    { id: 'lindi', name: 'Lindi' },
    { id: 'mafia', name: 'Mafia' },
    { id: 'mafia-island', name: 'Mafia Island' },
    { id: 'masasi', name: 'Masasi' },
    { id: 'mbeya', name: 'Mbeya' },
    { id: 'morogoro', name: 'Morogoro' },
    { id: 'moshi', name: 'Moshi' },
    { id: 'mpanda', name: 'Mpanda' },
    { id: 'mtwara', name: 'Mtwara' },
    { id: 'mwanza', name: 'Mwanza' },
    { id: 'musoma', name: 'Musoma' },
    { id: 'njombe', name: 'Njombe' },
    { id: 'shinyanga', name: 'Shinyanga' },
    { id: 'singida', name: 'Singida' },
    { id: 'sumbawanga', name: 'Sumbawanga' },
    { id: 'tabora', name: 'Tabora' },
    { id: 'tanga', name: 'Tanga' },
    { id: 'songea', name: 'Songea' },
    { id: 'tarime', name: 'Tarime' },
    { id: 'zanzibar', name: 'Zanzibar' },
    { id: 'unguja', name: 'Unguja' },
    { id: 'pemba', name: 'Pemba' },
  ];
  
  
  // Function to upload categories
const uploadCategories = async () => {
    try {
      for (const category of categories) {
        await setDoc(doc(db, 'categories', category.id), {
          name: category.name,
          icon: category.icon,
          subcategories: category.subcategories,
          createdAt: new Date(),
          updatedAt: new Date()
        });
        console.log(`Uploaded category: ${category.name}`);
      }
      console.log('All categories uploaded successfully!');
      return true;
    } catch (error) {
      console.error('Error uploading categories:', error);
      return false;
    }
  };
  
  // Function to upload cities
  const uploadCities = async () => {
    try {
      for (const city of cities) {
        await setDoc(doc(db, 'cities', city.id), {
          name: city.name,
          createdAt: new Date(),
          updatedAt: new Date()
        });
        console.log(`Uploaded city: ${city.name}`);
      }
      console.log('All cities uploaded successfully!');
      return true;
    } catch (error) {
      console.error('Error uploading cities:', error);
      return false;
    }
  };
  
  // Main upload function
  const uploadAllData = async () => {
    console.log('Starting data upload...');
    
    const categoriesSuccess = await uploadCategories();
    const citiesSuccess = await uploadCities();
    
    if (categoriesSuccess && citiesSuccess) {
      console.log('All data uploaded successfully!');
    } else {
      console.log('Upload completed with some errors');
    }
  };
  
  // Temporary Component
  const BulkUploadButton = () => {
    const handleUpload = async () => {
      await uploadAllData();
      alert('Upload process completed!');
    };
  
    return (
      <div>
        <button onClick={handleUpload}>
          Bulk Upload Categories & Cities
        </button>
        <p>Run this once, then delete the component</p>
      </div>
    );
  };

export default BulkUploadButton;