export const categories = [
    { id: 1, name: "Automobiles", icon: "🚗" },
    { id: 2, name: "Phones & Tablets", icon: "📱" },
    { id: 3, name: "Electronics", icon: "💻" },
    { id: 4, name: "Furniture", icon: "🛋️" },
    { id: 5, name: "Real Estate", icon: "🏠" },
    { id: 6, name: "Pets", icon: "🐕" },
    { id: 7, name: "Fashion", icon: "👕" },
    { id: 8, name: "Beauty", icon: "💄" },
    { id: 9, name: "Jobs", icon: "💼" },
    { id: 10, name: "Services", icon: "🔧" },
    { id: 11, name: "Learning", icon: "📚" },
    { id: 12, name: "Events", icon: "🎉" }
];

const categoryMap = Object.fromEntries(categories.map(cat => [cat.name, cat.id]));

const products = [
    {
      id: 1,
      title: "iPhone 13 Pro Max 256GB",
      price: 1200000,
      category: categoryMap["Phones & Tablets"],
      location: "Dar es Salaam",
      posted: "2 hours ago",
      description: "Like new condition, with original box and accessories. Battery health 98%.",
      images: [
        "https://images.unsplash.com/photo-1633891120686-3cc5e24a61d1",
        "https://images.unsplash.com/photo-1633891120862-5844e6c2ec6f"
      ],
      seller: {
        name: "John Doe",
        rating: 4.8,
        verified: true
      }
    },
    {
      id: 2,
      title: "2018 Toyota RAV4",
      price: 45000000,
      category: categoryMap["Automobiles"],
      location: "Arusha",
      posted: "1 day ago",
      description: "Well maintained SUV with full service history. 45,000 km mileage.",
      images: [
        "https://images.unsplash.com/photo-1568605117036-5fe5e7bab0b7"
      ],
      seller: {
        name: "Auto Dealers TZ",
        rating: 4.5,
        verified: true
      }
    },
    {
      id: 3,
      title: "3 Bedroom House for Rent",
      price: 1500000,
      category: categoryMap["Real Estate"],
      location: "Mwanza",
      posted: "3 days ago",
      description: "Furnished house with garden and security. Available immediately.",
      images: [
        "https://images.unsplash.com/photo-1564013799919-ab600027ffc6"
      ],
      seller: {
        name: "Prime Properties",
        rating: 4.9,
        verified: true
      }
    },
    {
      id: 4,
      title: "Samsung Galaxy Tab S7",
      price: 850000,
      category: categoryMap["Phones & Tablets"],
      location: "Dodoma",
      posted: "5 hours ago",
      description: "With keyboard cover and stylus. Warranty until next year.",
      images: [
        "https://images.unsplash.com/photo-1598546722978-95f935f2a71b"
      ],
      seller: {
        name: "Tech Gadgets",
        rating: 4.7,
        verified: true
      }
    },
    {
      id: 5,
      title: "Leather Office Chair",
      price: 120000,
      category: categoryMap["Furniture"],
      location: "Dar es Salaam",
      posted: "1 week ago",
      description: "Ergonomic executive chair in perfect condition.",
      images: [
        "https://images.unsplash.com/photo-1598300042247-d088f8ab3a91"
      ],
      seller: {
        name: "Furniture World",
        rating: 4.3,
        verified: false
      }
    },
    {
      id: 6,
      title: "German Shepherd Puppies",
      price: 300000,
      category: categoryMap["Pets"],
      location: "Mbeya",
      posted: "Yesterday",
      description: "Pure breed puppies with vaccination records. 8 weeks old.",
      images: [
        "https://images.unsplash.com/photo-1586671267731-da2cf3ceeb80"
      ],
      seller: {
        name: "Pet Lovers TZ",
        rating: 4.9,
        verified: true
      }
    },
    {
      id: 7,
      title: "MacBook Pro M1 2020",
      price: 1800000,
      category: categoryMap["Electronics"],
      location: "Dar es Salaam",
      posted: "4 hours ago",
      description: "16GB RAM, 512GB SSD, like new condition with warranty.",
      images: [
        "https://images.unsplash.com/photo-1611186871348-b1ce696e52c9"
      ],
      seller: {
        name: "Tech Hub",
        rating: 4.7,
        verified: true
      }
    },
    {
      id: 8,
      title: "Canon EOS 5D Mark IV",
      price: 3200000,
      category: categoryMap["Electronics"],
      location: "Zanzibar",
      posted: "2 days ago",
      description: "Professional DSLR camera with 24-105mm lens kit.",
      images: [
        "https://images.unsplash.com/photo-1516035069371-29a1b244cc32"
      ],
      seller: {
        name: "Photo Pro",
        rating: 4.8,
        verified: true
      }
    },
    {
      id: 9,
      title: "Sofa Set (3+2+1)",
      price: 950000,
      category: categoryMap["Furniture"],
      location: "Arusha",
      posted: "1 day ago",
      description: "Premium leather sofa set in excellent condition.",
      images: [
        "https://images.unsplash.com/photo-1555041469-a586c61ea9bc"
      ],
      seller: {
        name: "Home Comfort",
        rating: 4.6,
        verified: true
      }
    },
    {
      id: 10,
      title: "PlayStation 5",
      price: 1500000,
      category: categoryMap["Electronics"],
      location: "Dar es Salaam",
      posted: "6 hours ago",
      description: "Brand new PS5 with 2 controllers and 3 games.",
      images: [
        "https://images.unsplash.com/photo-1607853202273-797f1c22a38e"
      ],
      seller: {
        name: "Game Zone",
        rating: 4.9,
        verified: true
      }
    },
    {
      id: 11,
      title: "Dining Table Set",
      price: 450000,
      category: categoryMap["Furniture"],
      location: "Mwanza",
      posted: "3 days ago",
      description: "6-seater wooden dining table with chairs.",
      images: [
        "https://images.unsplash.com/photo-1567538096630-e0c55bd6374c"
      ],
      seller: {
        name: "Furniture Gallery",
        rating: 4.4,
        verified: true
      }
    },
    {
      id: 12,
      title: "DJI Mavic Air 2",
      price: 2200000,
      category: categoryMap["Electronics"],
      location: "Dar es Salaam",
      posted: "1 day ago",
      description: "Drone with 4K camera and 3 batteries.",
      images: [
        "https://images.unsplash.com/photo-1579829366248-204fe8413f31"
      ],
      seller: {
        name: "Aerial Views",
        rating: 4.7,
        verified: true
      }
    }
];

export { products };
export default products;
