import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import DiamondIcon from '@mui/icons-material/Diamond';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const PricingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const PricingCard = styled(Paper)(({ theme, featured }) => ({
    width: isMobile ? '95%' : '100%',
    margin: isMobile ? '0 auto' : 0,
    padding: isMobile ? '24px 16px' : '32px 24px',
    borderRadius: '12px',
    boxShadow: featured ? '0 16px 32px rgba(0, 0, 0, 0.15)' : '0 8px 16px rgba(0, 0, 0, 0.05)',
    border: featured ? `2px solid ${theme.palette.primary.main}` : '1px solid #e0e0e0',
    position: 'relative',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: isMobile ? 'none' : 'translateY(-8px)',
      boxShadow: featured ? '0 20px 40px rgba(0, 0, 0, 0.2)' : '0 12px 24px rgba(0, 0, 0, 0.1)'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      maxWidth: '400px'
    }
  }));

  const FeaturedBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '4px 16px',
    borderBottomLeftRadius: '12px',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  }));

  const plans = [
    {
      name: 'Mwananchi',
      price: 'Free',
      period: '',
      features: [
        '1 active listing',
        'Basic seller profile',
        'Limited customer support',
        'Standard visibility'
      ],
      buttonText: 'Get Started',
      color: 'default',
      link: '/create-listing' // Added link for free plan
    },
    {
      name: 'Biashara',
      price: '5,000',
      period: '/month',
      features: [
        '10 active listings',
        'Verified seller badge',
        'Priority customer support',
        'Increased visibility',
        'Basic analytics'
      ],
      buttonText: 'Choose Plan',
      color: 'primary',
      featured: true,
      link: '/create-listing' // Added link for premium plan
    },
    {
      name: 'Corporate',
      price: '15,000',
      period: '/month',
      features: [
        'Unlimited listings',
        'Premium seller badge',
        '24/7 dedicated support',
        'Maximum visibility',
        'Advanced analytics',
        'Featured listings'
      ],
      buttonText: 'Choose Plan',
      color: 'secondary',
      link: '/create-listing' // Added link for enterprise plan
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: isMobile ? 4 : 8 }}>
      <Box textAlign="center" mb={isMobile ? 4 : 8}>
        <Typography
          variant="overline"
          color="primary"
          sx={{ fontSize: '16px', fontWeight: 'bold', mb: 1 }}
        >
          PRICING PLANS
        </Typography>
        <Typography variant={isMobile ? 'h4' : 'h3'} component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
          Simple, transparent pricing
        </Typography>
        <Typography variant="body1" color="text.secondary" maxWidth="700px" mx="auto">
          Choose the perfect plan for your business needs. No hidden fees, cancel anytime.
        </Typography>
      </Box>

      <Grid 
        container 
        spacing={isMobile ? 4 : 4} 
        alignItems="stretch"
        justifyContent="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        {plans.map((plan, index) => (
          <Grid 
            item 
            xs={12} 
            md={4} 
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                maxWidth: '400px'
              }
            }}
          >
            <PricingCard featured={plan.featured} elevation={0}>
              {plan.featured && (
                <FeaturedBadge>
                  <StarIcon fontSize="small" /> POPULAR
                </FeaturedBadge>
              )}
              <Box textAlign="center" mb={3}>
                <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                  {plan.name === 'Corporate' && (
                    <DiamondIcon color="secondary" sx={{ mr: 1, fontSize: '28px' }} />
                  )}
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: 'bold', color: plan.featured ? 'primary.main' : 'inherit' }}
                  >
                    {plan.name}
                  </Typography>
                </Box>
                <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', mb: 0 }}>
                  {plan.price}
                  {plan.period && (
                    <Typography
                      component="span"
                      variant="h6"
                      color="text.secondary"
                      sx={{ fontWeight: 'normal' }}
                    >
                      {plan.period}
                    </Typography>
                  )}
                </Typography>
              </Box>

              <Divider sx={{ my: 2 }} />

              <List dense disablePadding>
                {plan.features.map((feature, i) => (
                  <ListItem key={i} disableGutters sx={{ py: 0.5 }}>
                    <ListItemIcon sx={{ minWidth: '32px' }}>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={feature} 
                      primaryTypographyProps={{ variant: isMobile ? 'body2' : 'body1' }} 
                    />
                  </ListItem>
                ))}
              </List>

              <Box mt={4}>
                <Button
                  component={RouterLink}
                  to={plan.link}
                  fullWidth
                  variant={plan.featured ? 'contained' : 'outlined'}
                  color={plan.color}
                  size="large"
                  sx={{
                    py: 1.5,
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    fontSize: isMobile ? '14px' : '16px'
                  }}
                >
                  {plan.buttonText}
                </Button>
              </Box>
            </PricingCard>
          </Grid>
        ))}
      </Grid>

      <Box mt={isMobile ? 6 : 10} textAlign="center" sx={{ px: isMobile ? 2 : 0 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
          Need something custom?
        </Typography>
        <Typography variant="body1" color="text.secondary" maxWidth="600px" mx="auto" mb={3}>
          We offer enterprise solutions for large businesses with special requirements.
        </Typography>
        <Button 
          component={RouterLink}
          to="/contact-sales"
          variant="outlined" 
          color="inherit" 
          size="large" 
          sx={{ 
            px: 4,
            fontSize: isMobile ? '14px' : 'inherit'
          }}
        >
          Contact Sales
        </Button>
      </Box>
    </Container>
  );
};

export default PricingPage;