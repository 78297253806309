import React, { useState, useEffect, useRef } from 'react';
import { 
  Container, Box, TextField, Button, 
  Avatar, Typography, Paper, List, 
  ListItem, ListItemAvatar, ListItemText,
  IconButton, Divider, Badge, useTheme,
  useMediaQuery, Chip, Dialog, MenuItem,
  DialogTitle, DialogContent, DialogActions, 
  CircularProgress, Select
} from '@mui/material';
import { 
  Send, ArrowBack, MoreVert, 
  AttachFile, DoneAll, InsertPhoto, Forum,
  FiberManualRecord, ShoppingBag
} from '@mui/icons-material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc, serverTimestamp, orderBy, doc, getDoc, updateDoc, getDocs } from 'firebase/firestore';
import { db, storage } from '../config/firebase';
import { useAuth } from '../context/AuthContext';
import { styled } from '@mui/material/styles';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const ChatContainer = styled(Container)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
    height: 'calc(100vh - 64px)',
    borderRadius: '12px',
    boxShadow: theme.shadows[3],
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
}));

const ChatPage = () => {
  const { recipientId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [recipient, setRecipient] = useState(null);
  const [product, setProduct] = useState(null);
  const [sellerProducts, setSellerProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOnline, setIsOnline] = useState(false);
  const messagesEndRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get product data from location state with proper fallback
  const { productId: initialProductId, productTitle: initialProductTitle, productImage: initialProductImage } = location.state || {};
  const safeProductId = initialProductId || null;

  useEffect(() => {
    if (!currentUser || !recipientId) return;

    const fetchRecipientAndProducts = async () => {
      // Fetch recipient details
      const recipientRef = doc(db, 'users', recipientId);
      const recipientSnap = await getDoc(recipientRef);
      
      if (recipientSnap.exists()) {
        const recipientData = recipientSnap.data();
        setRecipient(recipientData);
        setIsOnline(recipientData.isOnline || false);
        
        // Listen for online status changes
        const statusRef = doc(db, 'status', recipientId);
        const unsubscribeStatus = onSnapshot(statusRef, (doc) => {
          setIsOnline(doc.exists() ? doc.data().state === 'online' : false);
        });

        // Fetch seller's products
        const productsQuery = query(
          collection(db, 'listings'),
          where('userId', '==', recipientId),
          where('status', '==', 'active'),
          orderBy('createdAt', 'desc')
        );
        
        const productsSnapshot = await getDocs(productsQuery);
        const products = productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setSellerProducts(products);
        
        // Set initial product if provided
        if (safeProductId) {
          const initialProduct = products.find(p => p.id === safeProductId) || {
            id: safeProductId,
            title: initialProductTitle,
            images: initialProductImage ? [initialProductImage] : []
          };
          setProduct(initialProduct);
          setCurrentProduct(safeProductId);
        }

        return unsubscribeStatus;
      }
    };

    // Create or get chat room
    const chatRoomId = [currentUser.uid, recipientId].sort().join('_');
    const chatsRef = collection(db, 'chats');
    const chatQuery = query(chatsRef, where('participants', 'array-contains', currentUser.uid));
    
    getDocs(chatQuery).then((snapshot) => {
      let chatExists = false;
      snapshot.forEach((doc) => {
        const participants = doc.data().participants;
        if (participants.includes(recipientId)) {
          chatExists = true;
        }
      });

      if (!chatExists && safeProductId && initialProductTitle) {
        addDoc(chatsRef, {
          participants: [currentUser.uid, recipientId],
          createdAt: serverTimestamp(),
          productId: safeProductId,
          productTitle: initialProductTitle,
          productImage: initialProductImage
        }).then(() => {
          const messagesRef = collection(db, 'chats', chatRoomId, 'messages');
          addDoc(messagesRef, {
            text: `Hi, I'm interested in your product "${initialProductTitle}"`,
            senderId: currentUser.uid,
            recipientId,
            timestamp: serverTimestamp(),
            read: false,
            isGreeting: true,
            productId: safeProductId,
            productTitle: initialProductTitle
          });
        });
      }
    });

    // Get messages
    const messagesRef = collection(db, 'chats', chatRoomId, 'messages');
    const q = query(messagesRef, orderBy('timestamp'));
    const unsubscribeMessages = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setMessages(messagesData);
      setLoading(false);
      
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);

      // Mark messages as read
      messagesData.forEach(async (msg) => {
        if (msg.senderId === recipientId && !msg.read) {
          const messageRef = doc(db, 'chats', chatRoomId, 'messages', msg.id);
          await updateDoc(messageRef, { read: true });
        }
      });
    });

    const statusCleanup = fetchRecipientAndProducts();

    return () => {
      unsubscribeMessages();
      if (statusCleanup && typeof statusCleanup.then === 'function') {
        statusCleanup.then(fn => fn && fn());
      }
    };
  }, [currentUser, recipientId, safeProductId, initialProductTitle, initialProductImage]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !currentUser || !recipientId) return;

    const chatRoomId = [currentUser.uid, recipientId].sort().join('_');
    const messagesRef = collection(db, 'chats', chatRoomId, 'messages');

    const messageData = {
      text: newMessage,
      senderId: currentUser.uid,
      recipientId,
      timestamp: serverTimestamp(),
      read: false
    };

    // Include product reference if selected
    if (currentProduct) {
      const selectedProduct = sellerProducts.find(p => p.id === currentProduct) || product;
      if (selectedProduct) {
        messageData.productId = selectedProduct.id;
        messageData.productTitle = selectedProduct.title;
      }
    }

    try {
      await addDoc(messagesRef, messageData);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const chatRoomId = [currentUser.uid, recipientId].sort().join('_');
      const storageRef = ref(storage, `chats/${chatRoomId}/${uuidv4()}-${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      const messageData = {
        imageUrl: downloadURL,
        senderId: currentUser.uid,
        recipientId,
        timestamp: serverTimestamp(),
        read: false
      };

      if (currentProduct) {
        const selectedProduct = sellerProducts.find(p => p.id === currentProduct) || product;
        if (selectedProduct) {
          messageData.productId = selectedProduct.id;
          messageData.productTitle = selectedProduct.title;
        }
      }

      await addDoc(collection(db, 'chats', chatRoomId, 'messages'), messageData);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (date) => {
    return date?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const getCurrentProductDetails = () => {
    if (!currentProduct) return null;
    return sellerProducts.find(p => p.id === currentProduct) || product;
  };

  return (
    <ChatContainer maxWidth={false} disableGutters>
      {/* Header */}
      <Paper elevation={0} sx={{ 
        display: 'flex', 
        alignItems: 'center',
        p: 2,
        bgcolor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        zIndex: 1,
      }}>
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          <ArrowBack />
        </IconButton>
        
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography variant="subtitle1" fontWeight={600}>
              {recipient?.displayName || 'Loading...'}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FiberManualRecord 
                fontSize="small" 
                sx={{ 
                  color: isOnline ? 'success.main' : 'text.disabled',
                  mr: 0.5 
                }} 
              />
              <Typography variant="caption" color="text.secondary">
                {isOnline ? 'Online' : 'Offline'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Divider />

      {/* Product selector for multiple products */}
      {sellerProducts.length > 0 && (
        <Box sx={{ p: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
          <Select
            value={currentProduct || ''}
            onChange={(e) => {
              setCurrentProduct(e.target.value);
              const selected = sellerProducts.find(p => p.id === e.target.value);
              if (selected) setProduct(selected);
            }}
            fullWidth
            displayEmpty
            renderValue={(selected) => {
              if (!selected) return 'Select a product';
              const product = sellerProducts.find(p => p.id === selected);
              return product?.title || 'Product';
            }}
          >
            <MenuItem value="">
              <em>General Inquiry</em>
            </MenuItem>
            {sellerProducts.map(product => (
              <MenuItem key={product.id} value={product.id}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {product.images?.[0] && (
                    <img 
                      src={product.images[0]} 
                      alt={product.title}
                      style={{
                        width: 40,
                        height: 40,
                        objectFit: 'cover',
                        borderRadius: 4,
                        marginRight: 8
                      }}
                    />
                  )}
                  <Box>
                    <Typography>{product.title}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      TSh {product.price?.toLocaleString() || 'Price not set'}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      {/* Current product info */}
      {currentProduct && getCurrentProductDetails() && (
        <Paper elevation={0} sx={{ 
          p: 2, 
          m: 2, 
          borderRadius: 2,
          backgroundColor: theme.palette.grey[100],
          display: 'flex',
          alignItems: 'center'
        }}>
          <Box sx={{ width: 60, height: 60, mr: 2 }}>
            <img 
              src={getCurrentProductDetails()?.images?.[0]} 
              alt={getCurrentProductDetails()?.title}
              style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover',
                borderRadius: 4
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2" fontWeight={600}>
              {getCurrentProductDetails()?.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {getCurrentProductDetails()?.price ? 
                `TSh ${getCurrentProductDetails()?.price.toLocaleString()}` : 
                'Price not set'}
            </Typography>
          </Box>
        </Paper>
      )}

      {/* Messages area */}
      <List sx={{ 
        flex: 1, 
        overflowY: 'auto', 
        p: 2,
        '&::-webkit-scrollbar': { width: '6px' },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.2)',
          borderRadius: '3px',
        },
      }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
            <CircularProgress />
          </Box>
        ) : messages.length === 0 ? (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%',
            textAlign: 'center',
            p: 4
          }}>
            <Forum sx={{ fontSize: 60, color: theme.palette.text.secondary, mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No messages yet
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Start your conversation with {recipient?.displayName || 'the seller'}
            </Typography>
          </Box>
        ) : (
          messages.map((message) => (
            <React.Fragment key={message.id}>
              {/* Show product reference if this message is about a different product */}
              {message.productId && message.productId !== messages[messages.indexOf(message) - 1]?.productId && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  my: 1,
                  px: 2,
                  py: 1,
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: 2
                }}>
                  <ShoppingBag fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="caption" fontWeight={500}>
                    About: {message.productTitle}
                  </Typography>
                </Box>
              )}
              
              <ListItem 
                sx={{
                  px: 1,
                  py: 0.5,
                  justifyContent: message.senderId === currentUser?.uid ? 'flex-end' : 'flex-start'
                }}
              >
                {message.senderId !== currentUser?.uid && (
                  <ListItemAvatar sx={{ minWidth: '40px', alignSelf: 'flex-end' }}>
                    <Avatar 
                      src={recipient?.photoURL}
                      sx={{ width: 32, height: 32 }}
                    />
                  </ListItemAvatar>
                )}
                
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: message.senderId === currentUser?.uid ? 'flex-end' : 'flex-start',
                  maxWidth: '75%'
                }}>
                  {message.imageUrl ? (
                    <>
                      <Box 
                        component="img"
                        src={message.imageUrl}
                        alt="Chat image"
                        sx={{ 
                          maxWidth: '100%',
                          maxHeight: '300px',
                          borderRadius: 2,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setSelectedImage(message.imageUrl);
                          setImageDialogOpen(true);
                        }}
                      />
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                        {formatTime(message.timestamp)}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Paper 
                        elevation={0}
                        sx={{
                          p: 2,
                          borderRadius: message.senderId === currentUser?.uid 
                            ? '18px 4px 18px 18px' 
                            : '4px 18px 18px 18px',
                          backgroundColor: message.senderId === currentUser?.uid 
                            ? theme.palette.primary.main 
                            : theme.palette.grey[100],
                          color: message.senderId === currentUser?.uid ? '#fff' : theme.palette.text.primary,
                          wordBreak: 'break-word',
                        }}
                      >
                        <Typography>{message.text}</Typography>
                      </Paper>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        mt: 0.5,
                        gap: 0.5
                      }}>
                        <Typography variant="caption" color="text.secondary">
                          {formatTime(message.timestamp)}
                        </Typography>
                        {message.senderId === currentUser?.uid && (
                          <>
                            {message.read ? (
                              <DoneAll fontSize="small" color="primary" />
                            ) : (
                              <DoneAll fontSize="small" color="disabled" />
                            )}
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </ListItem>
            </React.Fragment>
          ))
        )}
        <div ref={messagesEndRef} />
      </List>

      {/* Message input area */}
      <Paper elevation={0} sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        p: 1,
        bgcolor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
      }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="chat-image-upload"
          type="file"
          onChange={handleImageUpload}
        />
        <label htmlFor="chat-image-upload">
          <IconButton component="span">
            <InsertPhoto />
          </IconButton>
        </label>
        
        <TextField
          fullWidth
          variant="outlined"
          placeholder={`Type a message about ${currentProduct ? getCurrentProductDetails()?.title : 'your inquiry'}`}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          size="small"
          sx={{ 
            mx: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '24px',
              backgroundColor: theme.palette.grey[100],
            }
          }}
        />
        
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          disabled={!newMessage.trim() || loading}
          sx={{ 
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
            '&:disabled': {
              backgroundColor: theme.palette.grey[300],
            }
          }}
        >
          <Send />
        </IconButton>
      </Paper>

      {/* Image preview dialog */}
      <Dialog 
        open={imageDialogOpen} 
        onClose={() => setImageDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          <img 
            src={selectedImage} 
            alt="Full size" 
            style={{ 
              width: '100%', 
              height: 'auto',
              maxHeight: '70vh',
              objectFit: 'contain'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImageDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </ChatContainer>
  );
};

export default ChatPage;