//categorie inputs
import React from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Autocomplete,
  Chip,
  Divider,
  Typography,
  InputAdornment,
  Box // Added Box for Checkbox group consistency
} from '@mui/material';

// categoryFields definition remains the same as you provided
export const categoryFields = {
    // Automobiles
    "Cars": [
      { name: "brand", label: "Car Brand", type: "select", options: ["Toyota", "Honda", "Ford", "BMW", "Mercedes", "Audi", "SEAT", "Nissan", "Volkswagen", "Hyundai", "Other"] },
      { name: "model", label: "Car Model", type: "text" },
      { name: "year", label: "Year of registration", type: "number", min: 1900, max: new Date().getFullYear() },
      { name: "fuelType", label: "Fuel Type", type: "select", options: ["Petrol", "Diesel", "Electric", "Hybrid", "LPG"] },
      { name: "transmission", label: "Transmission", type: "radio", options: ["Automatic", "Manual", "Semi-automatic"] },
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used (Foreign)", "Used (Local)", "Refurbished", "For parts"] }, // Added Used types
      { name: "mileage", label: "Mileage (km)", type: "number", min: 0 },
      { name: "engineSize", label: "Engine Size (cc)", type: "number", min: 0 },
      { name: "color", label: "Color", type: "text" },
      { name: "features", label: "Features", type: "checkbox", options: ["Air Conditioner", "GPS", "Security System", "Spare Tire", "Sunroof", "Alloy Wheels", "Leather Seats", "Parking Sensors", "Camera", "Bluetooth"] }, // Added Bluetooth
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" }, // Added currency flag
      { name: "negotiable", label: "Price is Negotiable", type: "checkbox" }, // Changed label
      { name: "city", label: "City", type: "select", options: ["Dar es Salaam", "Mwanza", "Arusha", "Dodoma", "Mbeya", "Morogoro", "Tanga", "Zanzibar", "Other"] }, // Added Other
      { name: "tags", label: "Tags (e.g., family car, low mileage)", type: "tags" } // Added example
    ],
    // --- Other categories remain the same as you provided ---
    "Buses & Minibus": [
      { name: "type", label: "Vehicle Type", type: "select", options: ["Minibus", "Bus", "Coaster", "School Bus", "Tourist Bus"] },
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "year", label: "Year", type: "number", min: 1900, max: new Date().getFullYear() },
      { name: "capacity", label: "Passenger Capacity", type: "number", min: 0 },
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "Heavy Equipment": [
      { name: "type", label: "Equipment Type", type: "select", options: ["Excavator", "Bulldozer", "Crane", "Forklift", "Tractor", "Road Roller", "Concrete Mixer"] },
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "year", label: "Year", type: "number", min: 1900, max: new Date().getFullYear() },
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "hoursUsed", label: "Hours Used", type: "number", min: 0 },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "Motorcycles & Scooters": [
      { name: "type", label: "Type", type: "select", options: ["Motorcycle", "Scooter", "Moped", "ATV", "Bajaji (Tuk-tuk)"] }, // Added Bajaji
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "year", label: "Year", type: "number", min: 1900, max: new Date().getFullYear() },
      { name: "engineSize", label: "Engine Size (cc)", type: "number", min: 0 },
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "mileage", label: "Mileage (km)", type: "number", min: 0 },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "Trucks & Trailers": [
      { name: "type", label: "Type", type: "select", options: ["Pickup Truck", "Lorry", "Tanker", "Trailer", "Tipper"] },
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "year", label: "Year", type: "number", min: 1900, max: new Date().getFullYear() },
      { name: "capacity", label: "Load Capacity (kg)", type: "number", min: 0 },
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],

    // Phones & Tablets
    "Mobile Phones": [
      { name: "brand", label: "Brand", type: "select", options: ["Apple", "Samsung", "Huawei", "Tecno", "Infinix", "Nokia", "Oppo", "Vivo", "Xiaomi", "Realme", "Google Pixel", "Other"] }, // Added Google Pixel, Other
      { name: "model", label: "Model", type: "text" },
      { name: "storage", label: "Storage (GB)", type: "number", min: 1 },
      { name: "ram", label: "RAM (GB)", type: "number", min: 1 },
      { name: "color", label: "Color", type: "text" },
      { name: "condition", label: "Condition", type: "select", options: ["New (Boxed)", "New (Open Box)", "Used", "Refurbished"] }, // More specific conditions
      { name: "network", label: "Network Lock", type: "select", options: ["Unlocked", "Locked"] }, // Changed label
      { name: "batteryHealth", label: "Battery Health (%)", type: "number", min: 0, max: 100, helperText:"For used phones, if known" }, // Added helper text
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "Accessories for Mobile Phones & Tablets": [
      { name: "type", label: "Accessory Type", type: "select", options: ["Case", "Charger", "Headphones/Earbuds", "Screen Protector", "Power Bank", "Cable", "Holder/Stand", "Stylus", "Memory Card"] }, // Added more options
      { name: "compatibleWith", label: "Compatible Device(s)", type: "text", helperText: "e.g., iPhone 14, Samsung Galaxy S23" }, // Added helper text
      { name: "brand", label: "Brand (if applicable)", type: "text" }, // Clarified label
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "Smart Watches & Trackers": [
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "features", label: "Features", type: "checkbox", options: ["Heart Rate Monitor", "GPS", "Water Resistant", "Sleep Tracker", "Step Counter", "Bluetooth Calling", "NFC Payment"] }, // Added more features
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "Tablets": [
      { name: "brand", label: "Brand", type: "select", options: ["Apple", "Samsung", "Huawei", "Lenovo", "Microsoft", "Amazon", "Other"] },
      { name: "model", label: "Model", type: "text" },
      { name: "screenSize", label: "Screen Size (inches)", type: "number", step: 0.1, min: 5 },
      { name: "storage", label: "Storage (GB)", type: "number", min: 1 },
      { name: "ram", label: "RAM (GB)", type: "number", min: 1 },
      { name: "connectivity", label: "Connectivity", type: "select", options: ["Wi-Fi Only", "Wi-Fi + Cellular"] }, // Added connectivity
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],

    // Electronics
    "Laptops & Computers": [
      { name: "type", label: "Type", type: "select", options: ["Laptop", "Desktop", "All-in-One", "Gaming Laptop", "Gaming Desktop"] }, // Added gaming types
      { name: "brand", label: "Brand", type: "select", options: ["Apple", "Dell", "HP", "Lenovo", "Asus", "Acer", "Microsoft", "Razer", "MSI", "Other"] }, // Added gaming brands
      { name: "model", label: "Model", type: "text" },
      { name: "processor", label: "Processor", type: "text", helperText: "e.g., Intel Core i5 11th Gen, AMD Ryzen 7" },
      { name: "ram", label: "RAM (GB)", type: "number", min: 1 },
      { name: "storage", label: "Storage Size (GB/TB)", type: "number", min: 1 }, // Clarified label
      { name: "storageType", label: "Storage Type", type: "select", options: ["HDD", "SSD", "HDD + SSD"] }, // Added combo
      { name: "graphicsCard", label: "Graphics Card (if applicable)", type: "text" }, // Added graphics card
      { name: "screenSize", label: "Screen Size (inches)", type: "number", step: 0.1, min: 10 },
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "TV & DVD Equipment": [
      { name: "type", label: "Type", type: "select", options: ["TV", "Smart TV", "DVD Player", "Blu-Ray Player", "Home Theater System", "Projector", "Soundbar", "TV Box/Stick"] }, // More options
      { name: "brand", label: "Brand", type: "text" },
      { name: "size", label: "Screen Size (inches, for TVs/Projectors)", type: "number", min: 10 }, // Clarified label
      { name: "resolution", label: "Resolution", type: "select", options: ["SD", "HD", "Full HD", "4K UHD", "8K"] }, // Added SD, UHD
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    "Audio & Music Equipment": [
      { name: "type", label: "Type", type: "select", options: ["Speakers (Bluetooth)", "Speakers (Wired)", "Headphones", "Earbuds", "Microphone", "Amplifier", "Mixer", "DJ Equipment", "Musical Instrument", "Studio Monitor"] }, // More options
      { name: "brand", label: "Brand", type: "text" },
      { name: "model", label: "Model", type: "text" },
      { name: "condition", label: "Condition", type: "select", options: ["New", "Used", "Refurbished"] },
      { name: "price", label: "Price", type: "number", min: 0, currency: "TSh" },
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],

    // Real Estate (Simplified for example)
    "Houses & Apartments For Rent": [
      { name: "propertyType", label: "Property Type", type: "select", options: ["House", "Apartment", "Studio", "Villa", "Townhouse", "Room"] }, // Added Room
      { name: "bedrooms", label: "Bedrooms", type: "number", min: 0 },
      { name: "bathrooms", label: "Bathrooms", type: "number", min: 0 },
      { name: "furnished", label: "Furnished Status", type: "select", options: ["Furnished", "Unfurnished", "Partially Furnished"] },
      { name: "location", label: "General Location / Area", type: "text" }, // Clarified label
      { name: "amenities", label: "Amenities", type: "checkbox", options: ["Parking", "Garden", "Pool", "Security", "Gym", "Backup Generator", "Air Conditioning", "Water Heater"] }, // Added more
      { name: "rentPrice", label: "Rent Price", type: "number", min: 0, currency: "TSh" },
      { name: "rentFrequency", label: "Rent Frequency", type: "select", options: ["Monthly", "Weekly", "Yearly"] }, // Added frequency
      { name: "deposit", label: "Deposit (TSh)", type: "number", min: 0 }
    ],
    "Houses & Apartments For Sale": [
      { name: "propertyType", label: "Property Type", type: "select", options: ["House", "Apartment", "Villa", "Townhouse", "Duplex", "Bungalow", "Mansion"] }, // Added more
      { name: "bedrooms", label: "Bedrooms", type: "number", min: 0 },
      { name: "bathrooms", label: "Bathrooms", type: "number", min: 0 },
      { name: "sizeSqm", label: "Size (sqm)", type: "number", min: 0 }, // Changed name for clarity
      { name: "plotSizeSqm", label: "Plot Size (sqm, if applicable)", type: "number", min: 0 }, // Added plot size
      { name: "furnished", label: "Furnished Status", type: "select", options: ["Furnished", "Unfurnished", "Partially Furnished"] },
      { name: "location", label: "General Location / Area", type: "text" },
      { name: "amenities", label: "Amenities", type: "checkbox", options: ["Parking", "Garden", "Pool", "Security", "Gym", "Backup Generator", "Servant Quarters"] }, // Added more
      { name: "price", label: "Asking Price", type: "number", min: 0, currency: "TSh" }, // Changed label
      { name: "negotiable", label: "Negotiable", type: "checkbox" }
    ],
    
    // Jobs
    "Accounting - Management - Finance": [
        { name: "jobTitle", label: "Job Title", type: "text" },
        { name: "jobType", label: "Job Type", type: "select", options: ["Full-time", "Part-time", "Contract", "Temporary", "Internship"] },
        { name: "experience", label: "Experience Required", type: "select", options: ["Entry Level", "1-2 years", "3-5 years", "5+ years"] },
        { name: "education", label: "Education Required", type: "select", options: ["High School", "Diploma", "Bachelor's", "Master's", "PhD"] },
        { name: "salary", label: "Salary (TSh)", type: "number", min: 0 },
        { name: "location", label: "Location", type: "text" },
        { name: "description", label: "Job Description", type: "text", multiline: true, rows: 4 }
      ],
      "Tourism - Hotels - Restaurants - Leisure": [
        { name: "jobTitle", label: "Job Title", type: "text" },
        { name: "jobType", label: "Job Type", type: "select", options: ["Full-time", "Part-time", "Seasonal", "Contract"] },
        { name: "industry", label: "Industry", type: "select", options: ["Hotel", "Restaurant", "Tour Operator", "Travel Agency", "Resort"] },
        { name: "experience", label: "Experience Required", type: "select", options: ["None", "1-2 years", "3-5 years", "5+ years"] },
        { name: "salary", label: "Salary (TSh)", type: "number", min: 0 },
        { name: "location", label: "Location", type: "text" }
      ],
    
      // Services
      "Computing Services": [
        { name: "serviceType", label: "Service Type", type: "select", options: ["Web Development", "Mobile App Development", "Software Development", "IT Support", "Network Setup", "Data Recovery"] },
        { name: "experience", label: "Years of Experience", type: "number", min: 0 },
        { name: "rate", label: "Rate (TSh)", type: "number", min: 0 },
        { name: "rateType", label: "Rate Type", type: "select", options: ["Hourly", "Daily", "Project-based"] },
        { name: "description", label: "Service Description", type: "text", multiline: true, rows: 4 }
      ],
      "Construction - Renovation - Carpentry": [
        { name: "serviceType", label: "Service Type", type: "select", options: ["Construction", "Renovation", "Carpentry", "Plumbing", "Electrical", "Painting"] },
        { name: "experience", label: "Years of Experience", type: "number", min: 0 },
        { name: "rate", label: "Rate (TSh)", type: "number", min: 0 },
        { name: "rateType", label: "Rate Type", type: "select", options: ["Hourly", "Daily", "Project-based"] },
        { name: "description", label: "Service Description", type: "text", multiline: true, rows: 4 }
      ],
    
      // Learning
      "Language Classes": [
        { name: "language", label: "Language", type: "select", options: ["English", "French", "Spanish", "Chinese", "Arabic", "Swahili", "German"] },
        { name: "level", label: "Level", type: "select", options: ["Beginner", "Intermediate", "Advanced"] },
        { name: "classType", label: "Class Type", type: "select", options: ["Individual", "Group", "Online"] },
        { name: "price", label: "Price (TSh)", type: "number", min: 0 },
        { name: "duration", label: "Duration (weeks)", type: "number", min: 1 }
      ],
      "Computer Courses": [
        { name: "courseName", label: "Course Name", type: "text" },
        { name: "category", label: "Category", type: "select", options: ["Programming", "Graphic Design", "Networking", "Database", "Office Applications"] },
        { name: "level", label: "Level", type: "select", options: ["Beginner", "Intermediate", "Advanced"] },
        { name: "classType", label: "Class Type", type: "select", options: ["Individual", "Group", "Online"] },
        { name: "price", label: "Price (TSh)", type: "number", min: 0 },
        { name: "duration", label: "Duration (weeks)", type: "number", min: 1 }
      ],
    
      // Local Events
      "Concerts & Festivals": [
        { name: "eventName", label: "Event Name", type: "text" },
        { name: "date", label: "Date", type: "date" },
        { name: "time", label: "Time", type: "time" },
        { name: "location", label: "Location", type: "text" },
        { name: "ticketPrice", label: "Ticket Price (TSh)", type: "number", min: 0 },
        { name: "description", label: "Description", type: "text", multiline: true, rows: 4 }
      ],
      "Weddings": [
        { name: "eventType", label: "Event Type", type: "select", options: ["Wedding Ceremony", "Reception", "Engagement", "Bridal Shower"] },
        { name: "date", label: "Date", type: "date" },
        { name: "time", label: "Time", type: "time" },
        { name: "location", label: "Location", type: "text" },
        { name: "guests", label: "Number of Guests", type: "number", min: 0 },
        { name: "description", label: "Description", type: "text", multiline: true, rows: 4 }
      ]
};


// --- CategoryInputs Component ---

// Define a default style object in case inputSx is not passed
const defaultInputSx = {
  width: '100%',
  mb: 3, // Margin bottom for spacing
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#f9f9f9',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[400], // Adjust hover based on mode
    },
    '&.Mui-focused': {
      backgroundColor: (theme) => theme.palette.background.paper, // Use theme background
      boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}33`,
       '& .MuiOutlinedInput-notchedOutline': {
        borderColor: (theme) => theme.palette.primary.main,
      },
    },
  },
   '& .MuiInputLabel-root': { // Ensure label is visible and shrinks correctly
      // color: (theme) => theme.palette.text.secondary, // Optional: control label color
       '&.Mui-focused': {
        // color: (theme) => theme.palette.primary.main, // Optional: color on focus
       },
   },
   '& .MuiInputLabel-shrink': {
      // transform: 'translate(14px, -9px) scale(0.75)', // Already handled by outlined variant generally
   },
  '& .MuiOutlinedInput-input': {
    padding: '14px 16px',
    fontSize: '0.95rem', // Consistent font size for inputs
  },
   '& .MuiSelect-select.MuiOutlinedInput-input': { // Specific padding for Select input
     padding: '14px 16px',
   },
};


export default function CategoryInputs({ category, formData, handleInputChange, inputSx }) {
  const fields = categoryFields[category] || [];

  // Use the passed inputSx or the default one defined above
  const sxToUse = inputSx || defaultInputSx;

  const handleCheckboxChange = (e, fieldName) => {
    const { checked, value } = e.target; // 'value' here is the specific option
    const currentValues = formData[fieldName] || [];
    const newValue = checked
      ? [...currentValues, value]
      : currentValues.filter(item => item !== value);

    // Simulate event object for handleInputChange
    handleInputChange({
      target: {
        name: fieldName,
        value: newValue,
        type: 'checkbox-group', // Add custom type if needed for parent logic
      }
    });
  };

   const handleSingleCheckboxChange = (e, fieldName) => {
    // Simulate event object for handleInputChange
    handleInputChange({
      target: {
        name: fieldName,
        value: e.target.checked, // boolean value
        type: 'checkbox',
      }
    });
  };


  const renderField = (field) => {
    const commonInputProps = {
      // Add any common InputProps here if needed across types
    };
    const commonLabelProps = {
      shrink: true, // Often needed for outlined variant when value might be empty initially
    };

    switch (field.type) {
      case 'text':
      case 'number': // Consolidate text and number as TextField handles type='number' well
      case 'date':
      case 'time': // Added time
        return (
          <TextField
            // Removed fullWidth prop as sxToUse handles width: '100%'
            type={field.type === 'text' ? 'text' : field.type} // Use field type (text, number, date, time)
            label={field.label}
            name={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            variant="outlined"
            multiline={field.multiline}
            rows={field.rows} // Only applies if multiline is true
            sx={sxToUse} // Apply the consistent styling object
            helperText={field.helperText} // Add helper text if defined
            inputProps={{
              min: field.min,
              max: field.max,
              step: field.step || (field.type === 'number' ? 1 : undefined), // Default step for number
              ...commonInputProps, // Spread common input props
            }}
            InputLabelProps={commonLabelProps} // Use common label props
            InputProps={{ // Specific InputProps for adornment etc.
              startAdornment: field.currency ? (
                <InputAdornment position="start">{field.currency}</InputAdornment>
              ) : null,
            }}
          />
        );

      case 'select':
        return (
          <FormControl variant="outlined" sx={sxToUse}> {/* Apply sx to FormControl */}
            <InputLabel shrink={true}>{field.label}</InputLabel> {/* Ensure label shrinks */}
            <Select
              label={field.label} // Label is needed for accessibility and positioning
              name={field.name}
              value={formData[field.name] || ''}
              onChange={handleInputChange}
              MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
            >
              <MenuItem value="" disabled><em>Select {field.label}</em></MenuItem>
              {field.options?.map(option => (
                <MenuItem key={option} value={option} sx={{ fontSize: '0.9rem' }}>
                  {option}
                </MenuItem>
              ))}
            </Select>
             {field.helperText && <Typography variant="caption" sx={{ pl: 2, color: 'text.secondary' }}>{field.helperText}</Typography>}
          </FormControl>
        );

      case 'radio':
        return (
          // Use Box for layout consistency with Checkbox groups
          <Box sx={sxToUse}>
             <FormControl component="fieldset" sx={{ width: '100%' }}>
                {/* Use FormLabel standard practice */}
                <Typography component="legend" variant="body2" sx={{ mb: 1, color:'text.secondary', fontSize: '0.8rem'}}>
                    {field.label}
                </Typography>
                <RadioGroup
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleInputChange}
                >
                    <Grid container spacing={0}> {/* Reduced spacing */}
                        {field.options?.map(option => (
                        <Grid item xs={12} sm={6} md={4} key={option}> {/* Allow multi-column layout for options */}
                            <FormControlLabel
                                value={option}
                                control={<Radio color="primary" size="small" />} // Smaller radio
                                label={
                                    <Typography variant="body2" fontSize="0.9rem"> {/* Slightly smaller label */}
                                    {option}
                                    </Typography>
                                }
                                sx={{ mr: 1 }} // Margin between options
                            />
                        </Grid>
                        ))}
                    </Grid>
                </RadioGroup>
                 {field.helperText && <Typography variant="caption" sx={{ color: 'text.secondary', mt: 1 }}>{field.helperText}</Typography>}
             </FormControl>
          </Box>
        );

      case 'checkbox':
        // Handle multi-option checkbox group vs single checkbox
        if (field.options && Array.isArray(field.options)) {
           // Use Box for consistent margin-bottom from sxToUse
          return (
            <Box sx={sxToUse}>
                <FormControl component="fieldset" sx={{ width: '100%' }}>
                    <Typography component="legend" variant="body2" sx={{ mb: 1, color:'text.secondary', fontSize: '0.8rem'}}>
                        {field.label}
                    </Typography>
                    <Grid container spacing={0}> {/* Reduced spacing */}
                    {field.options.map(option => (
                        <Grid item xs={12} sm={6} md={4} key={option}> {/* Multi-column layout for options */}
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={formData[field.name]?.includes(option) || false}
                                onChange={(e) => handleCheckboxChange(e, field.name)} // Use specific handler
                                value={option} // Pass value for handler
                                name={`${field.name}-${option}`} // Unique name for each checkbox if needed, though handler uses field.name
                                color="primary"
                                size="small" // Smaller checkbox
                            />
                            }
                            label={
                                <Typography variant="body2" fontSize="0.9rem"> {/* Smaller label */}
                                {option}
                                </Typography>
                            }
                            sx={{ mr: 1 }} // Margin between options
                        />
                        </Grid>
                    ))}
                    </Grid>
                    {field.helperText && <Typography variant="caption" sx={{ color: 'text.secondary', mt: 1 }}>{field.helperText}</Typography>}
                </FormControl>
            </Box>
          );
        } else {
          // Single standalone checkbox (like "Negotiable")
          return (
             <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(formData[field.name]) || false} // Ensure boolean conversion
                    onChange={(e) => handleSingleCheckboxChange(e, field.name)} // Use specific handler
                    name={field.name}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1" fontSize="0.95rem">
                    {field.label}
                  </Typography>
                }
                // Apply sx here directly, ensuring mb is handled
                // Note: sxToUse has mb: 3, might be too much for a single checkbox
                // Let's adjust: create a shallow copy and remove mb or set to 0/1
                sx={{ ...sxToUse, mb: 1, width: 'auto', display:'inline-flex' }} // Adjust margin, width is not 100%
              />
          );
        }

      case 'tags':
        return (
          <FormControl variant="outlined" sx={sxToUse}> {/* Apply sx to FormControl */}
            {/* Autocomplete needs InputLabel outside if shrink doesn't work well */}
            {/* <InputLabel shrink>{field.label}</InputLabel> */}
            <Autocomplete
              multiple
              freeSolo // Allow custom tags
              options={[]} // No predefined options, user types them
              value={formData[field.name] || []}
              onChange={(event, newValue) => {
                // Filter out any potential duplicates or empty strings if needed
                const uniqueValues = [...new Set(newValue.map(tag => tag.trim()).filter(Boolean))];
                handleInputChange({
                  target: { name: field.name, value: uniqueValues }
                });
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    size="small"
                    {...getTagProps({ index })}
                    sx={{ borderRadius: '6px', m: '2px' }} // Simplified margin
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined" // Match other fields
                  label={field.label}
                  placeholder="Type tag and press Enter..."
                  InputLabelProps={{ shrink: true }} // Ensure label shrinks
                  // Removed sx here as FormControl above handles it
                />
              )}
               // Removed internal sx on MuiOutlinedInput-root, rely on sxToUse on FormControl
            />
             {field.helperText && <Typography variant="caption" sx={{ pl: 2, color: 'text.secondary' }}>{field.helperText}</Typography>}
          </FormControl>
        );

      default:
        console.warn("Unsupported field type:", field.type);
        return null;
    }
  };

  return (
    // Use Box instead of Grid container for simple vertical stacking
    <Box>
      {fields.map((field) => (
         // Render each field directly. The sxToUse on each field handles spacing (mb)
         <React.Fragment key={field.name}>
            {renderField(field)}
         </React.Fragment>
      ))}
    </Box>
  );
}