export const categories = [
  {
    id: 'automobiles',
    name: "Automobiles",
    icon: 'directions_car',
    subcategories: [
      "Cars",
      "Buses & Minibus",
      "Heavy Equipment",
      "Motorcycles & Scooters",
      "Trucks & Trailers",
      "Vehicle Parts & Accessories",
      "Watercraft & Boats"
    ],
  },
  {
    id: 'phones-tablets',
    name: "Phones & Tablets",
    icon: 'smartphone',
    subcategories: [
      "Mobile Phones",
      "Accessories for Mobile Phones & Tablets",
      "Smart Watches & Trackers",
      "Tablets"
    ],
  },
  {
    id: 'electronics',
    name: "Electronics",
    icon: 'devices',
    subcategories: [
      "Accessories & Supplies for Electronics",
      "Laptops & Computers",
      "TV & DVD Equipment",
      "Audio & Music Equipment",
      "Computer Accessories",
      "Computer Hardware",
      "Computer Monitors",
      "Headphones",
      "Networking Products",
      "Photo & Video Cameras",
      "Printers & Scanners",
      "Security & Surveillance",
      "Software",
      "Video Games",
      "Game Consoles"
    ],
  },
  {
    id: 'furniture-appliances',
    name: "Furniture & Appliances",
    icon: 'chair',
    subcategories: [
      "Furniture - Tableware",
      "Antiques - Art - Decoration",
      "Appliances",
      "Garden",
      "Toys - Games - Figurines",
      "Wine & Gourmet - Recipes"
    ],
  },
  {
    id: 'real-estate',
    name: "Real Estate",
    icon: 'home',
    subcategories: [
      "Houses & Apartments For Rent",
      "Houses & Apartments For Sale",
      "Land & Plots for Rent",
      "Land & Plots For Sale",
      "Commercial Property For Rent",
      "Commercial Property For Sale",
      "Event centres, Venues and Workstations",
      "Short Rental"
    ],
  },
  {
    id: 'animals-pets',
    name: "Animals & Pets",
    icon: 'pets',
    subcategories: [
      "Birds",
      "Cats & Kittens",
      "Dogs",
      "Fish",
      "Pet's Accessories",
      "Reptiles",
      "Other Animals"
    ],
  },
  {
    id: 'fashion',
    name: "Fashion",
    icon: 'checkroom',
    subcategories: [
      "Bags",
      "Clothing",
      "Clothing Accessories",
      "Jewelry",
      "Shoes",
      "Watches",
      "Wedding Wear & Accessories"
    ],
  },
  {
    id: 'beauty-wellbeing',
    name: "Beauty & Well Being",
    icon: 'spa',
    subcategories: [
      "Bath & Body",
      "Fragrance",
      "Hair Beauty",
      "Makeup",
      "Sexual Wellness",
      "Skin Care",
      "Tobacco Accessories",
      "Tools & Accessories",
      "Vitamins & Supplements",
      "Pro Massage"
    ],
  },
  {
    id: 'jobs',
    name: "Jobs",
    icon: 'work',
    subcategories: [
      "Agriculture - Environment",
      "Assistantship - Secretariat - Helpdesk",
      "Automotive - Mechanic",
      "BTP - Construction - Building",
      "Trade - Business Services",
      "Commercial - Sale Jobs",
      "Accounting - Management - Finance",
      "Steering - Manager",
      "Aesthetics - Hair - Beauty",
      "Public Service Jobs",
      "Real Estate Jobs",
      "Independent - Freelance - Telecommuting",
      "Computers - Internet - Telecommunications",
      "Industry, Production & Engineering",
      "Marketing - Communication",
      "Babysitting - Nanny Work",
      "HR - Training - Education",
      "Medical - Healthcare - Social",
      "Security - Guarding",
      "Household Services - Housekeeping",
      "Tourism - Hotels - Restaurants - Leisure",
      "Transportation - Logistics",
      "Other Jobs Offer"
    ],
  },
  {
    id: 'services',
    name: "Services",
    icon: 'handyman',
    subcategories: [
      "Casting, Model, Photographer",
      "Carpooling",
      "Moving, Furniture Guard",
      "Destocking - Commercial",
      "Industrial Equipment",
      "Aesthetics, Hairstyling",
      "Materials and Equipment Pro",
      "Event Organization Services",
      "Service Provision",
      "Health, Beauty",
      "Artisan, Troubleshooting, Handyman",
      "Computing Services",
      "Tourism and Travel Services",
      "Translation, Writing",
      "Construction - Renovation - Carpentry",
      "Other Services"
    ],
  },
  {
    id: 'learning',
    name: "Learning",
    icon: 'school',
    subcategories: [
      "Language Classes",
      "Computer Courses",
      "Tutoring, Private Lessons",
      "Vocational Training",
      "Maths, Physics, Chemistry",
      "Music, Theatre, Dance",
      "School Support"
    ],
  },
  {
    id: 'local-events',
    name: "Local Events",
    icon: 'event',
    subcategories: [
      "Concerts & Festivals",
      "Networking & Meetups",
      "Sports & Outdoors",
      "Trade Shows & Conventions",
      "Training & Seminars",
      "Ceremonies",
      "Conferences",
      "Weddings",
      "Birthdays",
      "Family Events",
      "Nightlife",
      "All Other Events"
    ],
  }
];

export const categoryIcons = {
  // Automobiles
  "Cars": 'directions_car',
  "Buses & Minibus": 'airport_shuttle',
  "Heavy Equipment": 'construction',
  "Motorcycles & Scooters": 'motorcycle',
  "Trucks & Trailers": 'local_shipping',
  "Vehicle Parts & Accessories": 'build',
  "Watercraft & Boats": 'directions_boat',

  // Phones & Tablets
  "Mobile Phones": 'smartphone',
  "Accessories for Mobile Phones & Tablets": 'phone_iphone',
  "Smart Watches & Trackers": 'watch',
  "Tablets": 'tablet',

  // Electronics
  "Laptops & Computers": 'laptop',
  "TV & DVD Equipment": 'tv',
  "Audio & Music Equipment": 'speaker',
  "Computer Accessories": 'keyboard',
  "Computer Hardware": 'memory',
  "Computer Monitors": 'desktop_windows',
  "Headphones": 'headphones',
  "Networking Products": 'router',
  "Photo & Video Cameras": 'photo_camera',
  "Printers & Scanners": 'print',
  "Security & Surveillance": 'security',
  "Software": 'apps',
  "Video Games": 'sports_esports',
  "Game Consoles": 'videogame_asset',

  // Furniture & Appliances
  "Furniture - Tableware": 'chair',
  "Antiques - Art - Decoration": 'palette',
  "Appliances": 'kitchen',
  "Garden": 'yard',
  "Toys - Games - Figurines": 'toys',
  "Wine & Gourmet - Recipes": 'wine_bar',

  // Real Estate
  "Houses & Apartments For Rent": 'house',
  "Houses & Apartments For Sale": 'real_estate_agent',
  "Land & Plots for Rent": 'map',
  "Land & Plots For Sale": 'map',
  "Commercial Property For Rent": 'business',
  "Commercial Property For Sale": 'business',
  "Event centres, Venues and Workstations": 'meeting_room',
  "Short Rental": 'calendar_today',

  // Animals & Pets
  "Birds": 'flutter_dash',
  "Cats & Kittens": 'pets',
  "Dogs": 'pets',
  "Fish": 'pets',
  "Pet's Accessories": 'pets',
  "Reptiles": 'pets',
  "Other Animals": 'pets',

  // Fashion
  "Bags": 'shopping_bag',
  "Clothing": 'checkroom',
  "Clothing Accessories": 'accessibility',
  "Jewelry": 'diamond',
  "Shoes": 'shoes',
  "Watches": 'watch',
  "Wedding Wear & Accessories": 'celebration',

  // Beauty & Well Being
  "Bath & Body": 'bathtub',
  "Fragrance": 'spa',
  "Hair Beauty": 'content_cut',
  "Makeup": 'brush',
  "Sexual Wellness": 'favorite',
  "Skin Care": 'face',
  "Tobacco Accessories": 'smoking_rooms',
  "Tools & Accessories": 'build',
  "Vitamins & Supplements": 'local_pharmacy',
  "Pro Massage": 'spa',

  // Jobs
  "Agriculture - Environment": 'agriculture',
  "Assistantship - Secretariat - Helpdesk": 'support_agent',
  "Automotive - Mechanic": 'car_repair',
  "BTP - Construction - Building": 'construction',
  "Trade - Business Services": 'store',
  "Commercial - Sale Jobs": 'shopping_cart',
  "Accounting - Management - Finance": 'account_balance',
  "Steering - Manager": 'supervisor_account',
  "Aesthetics - Hair - Beauty": 'spa',
  "Public Service Jobs": 'local_police',
  "Real Estate Jobs": 'home_work',
  "Independent - Freelance - Telecommuting": 'work',
  "Computers - Internet - Telecommunications": 'computer',
  "Industry, Production & Engineering": 'factory',
  "Marketing - Communication": 'campaign',
  "Babysitting - Nanny Work": 'child_care',
  "HR - Training - Education": 'school',
  "Medical - Healthcare - Social": 'medical_services',
  "Security - Guarding": 'security',
  "Household Services - Housekeeping": 'cleaning_services',
  "Tourism - Hotels - Restaurants - Leisure": 'restaurant',
  "Transportation - Logistics": 'local_shipping',
  "Other Jobs Offer": 'work_outline',

  // Services
  "Casting, Model, Photographer": 'camera_alt',
  "Carpooling": 'carpool',
  "Moving, Furniture Guard": 'moving',
  "Destocking - Commercial": 'store',
  "Industrial Equipment": 'factory',
  "Aesthetics, Hairstyling": 'spa',
  "Materials and Equipment Pro": 'handyman',
  "Event Organization Services": 'event',
  "Service Provision": 'miscellaneous_services',
  "Health, Beauty": 'spa',
  "Artisan, Troubleshooting, Handyman": 'handyman',
  "Computing Services": 'computer',
  "Tourism and Travel Services": 'flight',
  "Translation, Writing": 'translate',
  "Construction - Renovation - Carpentry": 'carpenter',
  "Other Services": 'handyman',

  // Learning
  "Language Classes": 'translate',
  "Computer Courses": 'computer',
  "Tutoring, Private Lessons": 'school',
  "Vocational Training": 'work',
  "Maths, Physics, Chemistry": 'calculate',
  "Music, Theatre, Dance": 'music_note',
  "School Support": 'school',

  // Local Events
  "Concerts & Festivals": 'music_note',
  "Networking & Meetups": 'groups',
  "Sports & Outdoors": 'sports',
  "Trade Shows & Conventions": 'store',
  "Training & Seminars": 'school',
  "Ceremonies": 'celebration',
  "Conferences": 'groups',
  "Weddings": 'celebration',
  "Birthdays": 'cake',
  "Family Events": 'family_restroom',
  "Nightlife": 'nightlife',
  "All Other Events": 'event'
};

export const getCategoryIcon = (categoryName) => {
  return categoryIcons[categoryName] || 'category';
};

export const getSubcategories = (categoryName) => {
  const category = categories.find(cat => cat.name === categoryName);
  return category ? category.subcategories : [];
};

export const getAllSubcategories = () => {
  return categories.flatMap(category => 
    category.subcategories.map(subcategory => ({
      name: subcategory,
      parentCategory: category.name
    }))
  );
};