import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  Chip,
  Grid,
  CircularProgress,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import ProductCardWrapper from "../components/ProductCardWrapper";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  setDoc
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useAuth } from "../context/AuthContext";
import { useParams } from "react-router-dom";

const CategoryView = () => {
  const { categoryName } = useParams();
  const { currentUser } = useAuth();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedItems, setSavedItems] = useState({});
  const [priceFilter, setPriceFilter] = useState("all");
  const [sortBy, setSortBy] = useState("newest");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the category details
        const categoriesQuery = query(
          collection(db, "categories"),
          where("name", "==", decodeURIComponent(categoryName))
        );
        const categoriesSnapshot = await getDocs(categoriesQuery);
        
        if (categoriesSnapshot.empty) {
          console.error("Category not found");
          setLoading(false);
          return;
        }

        const categoryData = {
          id: categoriesSnapshot.docs[0].id,
          ...categoriesSnapshot.docs[0].data(),
        };
        setCategory(categoryData);

        // Fetch listings for this category
        const productsQuery = query(
          collection(db, "listings"),
          where("category", "in", [categoryData.id, categoryData.name])
        );
        const productsSnapshot = await getDocs(productsQuery);
        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);

        // Fetch saved items if user is logged in
        if (currentUser) {
          const savedRef = doc(db, "savedItems", currentUser.uid);
          const savedSnap = await getDoc(savedRef);
          if (savedSnap.exists()) {
            setSavedItems(savedSnap.data().items || {});
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryName, currentUser]);

  const getTimeAgo = (timestamp) => {
    if (!timestamp?.seconds) return 'Date not specified';
  
    const createdDate = new Date(timestamp.seconds * 1000);
    const now = new Date();
    const diffInSeconds = Math.floor((now - createdDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 30 * 86400) return `${Math.floor(diffInSeconds / 86400)} days ago`;
  
    return createdDate.toLocaleDateString();
  };

  const handleSaveClick = async (id, e) => {
    e.stopPropagation();
    if (!currentUser) {
      // Optionally redirect to login
      return;
    }

    try {
      const savedRef = doc(db, "savedItems", currentUser.uid);
      const newSavedItems = { ...savedItems };

      if (newSavedItems[id]) {
        delete newSavedItems[id];
      } else {
        newSavedItems[id] = true;
      }

      await setDoc(savedRef, { items: newSavedItems });
      setSavedItems(newSavedItems);
    } catch (error) {
      console.error("Error saving item:", error);
    }
  };

  const formatPrice = (price) => {
    if (!price) return "Negotiable";
    return new Intl.NumberFormat("en-TZ", {
      style: "currency",
      currency: "TZS",
      maximumFractionDigits: 0,
    }).format(price);
  };

  const getFilteredAndSortedProducts = () => {
    let filtered = [...products];

    // Apply price filter
    if (priceFilter === "under100k") {
      filtered = filtered.filter((product) => product.price && product.price < 100000);
    } else if (priceFilter === "100k-500k") {
      filtered = filtered.filter(
        (product) => product.price && product.price >= 100000 && product.price <= 500000
      );
    } else if (priceFilter === "over500k") {
      filtered = filtered.filter((product) => product.price && product.price > 500000);
    }

    // Apply sorting
    if (sortBy === "newest") {
      filtered.sort((a, b) => {
        const dateA = a.createdAt?.seconds || 0;
        const dateB = b.createdAt?.seconds || 0;
        return dateB - dateA;
      });
    } else if (sortBy === "priceLow") {
      filtered.sort((a, b) => (a.price || 0) - (b.price || 0));
    } else if (sortBy === "priceHigh") {
      filtered.sort((a, b) => (b.price || 0) - (a.price || 0));
    }

    return filtered;
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!category) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h5">Category not found</Typography>
      </Container>
    );
  }

  const filteredProducts = getFilteredAndSortedProducts();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link
          component={RouterLink}
          to="/"
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        <Link
          component={RouterLink}
          to="/categories"
          color="inherit"
        >
          Categories
        </Link>
        <Typography color="text.primary">{category.name}</Typography>
      </Breadcrumbs>

      {/* Category Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              backgroundColor: "primary.light",
              borderRadius: "50%",
              width: 60,
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "primary.contrastText",
            }}
          >
            {category.icon || <CategoryIcon fontSize="large" />}
          </Box>
          <Box>
            <Typography variant="h4" component="h1" sx={{ fontWeight: "bold" }}>
              {category.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {filteredProducts.length} listings available
            </Typography>
          </Box>
        </Box>

        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: { xs: "flex-start", sm: "flex-end" },
          }}
        >
          <Box>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
              Price Range
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Chip
                label="All"
                variant={priceFilter === "all" ? "filled" : "outlined"}
                color="primary"
                onClick={() => setPriceFilter("all")}
              />
              <Chip
                label="Under 100K"
                variant={priceFilter === "under100k" ? "filled" : "outlined"}
                color="primary"
                onClick={() => setPriceFilter("under100k")}
              />
              <Chip
                label="100K-500K"
                variant={priceFilter === "100k-500k" ? "filled" : "outlined"}
                color="primary"
                onClick={() => setPriceFilter("100k-500k")}
              />
              <Chip
                label="Over 500K"
                variant={priceFilter === "over500k" ? "filled" : "outlined"}
                color="primary"
                onClick={() => setPriceFilter("over500k")}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
              Sort By
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Chip
                label="Newest"
                variant={sortBy === "newest" ? "filled" : "outlined"}
                color="primary"
                onClick={() => setSortBy("newest")}
              />
              <Chip
                label="Price: Low"
                variant={sortBy === "priceLow" ? "filled" : "outlined"}
                color="primary"
                onClick={() => setSortBy("priceLow")}
              />
              <Chip
                label="Price: High"
                variant={sortBy === "priceHigh" ? "filled" : "outlined"}
                color="primary"
                onClick={() => setSortBy("priceHigh")}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Products Grid */}
      {filteredProducts.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 8,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            No listings found in this category
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Try adjusting your filters or check back later for new listings
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            },
            gap: 3,
          }}
        >
          {filteredProducts.map((product) => (
            <ProductCardWrapper
              key={product.id}
              product={product}
              savedItems={savedItems}
              handleSaveClick={handleSaveClick}
              category={category}
              getTimeAgo={getTimeAgo}
              formatPrice={formatPrice}
            />
          ))}
        </Box>
      )}
    </Container>
  );
};

export default CategoryView;