import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Box, Typography, Button, Paper, Grid, TextField,
  Card, CardMedia, CardActions, IconButton, Divider, FormControl,
  InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio,
  CircularProgress, Snackbar, Alert, CardContent, List, ListItem,
  ListItemText, Checkbox, Chip, Avatar, AvatarGroup, Badge, Menu
} from '@mui/material';
import { 
  PhotoCamera, Delete, CloudUpload, Stars, AccessTime, Add,
  ArrowBack, Save, Close, AttachMoney, Description, Category,
  Edit, Visibility, Archive, CheckCircle, PendingActions, FavoriteBorder
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, storage, auth } from '../config/firebase';
import CategoryInputs, { categoryFields } from '../components/CategoryInputs';
import { categories } from '../data/categories';

// --- Styled Components ---
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '12px 24px',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
}));

const FullWidthFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
    color: theme.palette.text.secondary,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiSelect-select': {
      padding: '14px 16px',
    },
  },
}));

const FullWidthTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
    color: theme.palette.text.secondary,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 16px',
  },
}));

const EditListing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const [formData, setFormData] = useState({
    listingType: 'individual',
    title: '',
    description: '',
    category: '',
    subcategory: '',
    location: '',
    price: '',
    negotiable: false,
    status: 'pending_review',
    ...Object.keys(categoryFields).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {})
  });

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const listingRef = doc(db, 'listings', id);
        const docSnap = await getDoc(listingRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Verify the listing belongs to the current user
          if (data.userId !== auth.currentUser?.uid) {
            navigate('/my-listings');
            return;
          }
          
          setListing(data);
          setImages(data.images || []);
          
          // Prepare form data with all fields
          const formData = {
            listingType: data.listingType || 'individual',
            title: data.title || '',
            description: data.description || '',
            category: data.category || '',
            subcategory: data.subcategory || '',
            location: data.location || '',
            price: data.price || '',
            negotiable: data.negotiable || false,
            status: data.status || 'pending_review',
          };

          // Add category-specific fields
          if (data.subcategory && categoryFields[data.subcategory]) {
            categoryFields[data.subcategory].forEach(field => {
              formData[field.name] = data[field.name] || '';
            });
          }

          setFormData(formData);
        } else {
          navigate('/my-listings');
        }
      } catch (err) {
        setError('Failed to fetch listing details');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchListing();
  }, [id, navigate]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const currentImageCount = images.length + newImages.length;
    const remainingSlots = 10 - currentImageCount;

    if (files.length > remainingSlots) {
      setSnackbar({ open: true, message: `You can only upload ${remainingSlots} more images (max 10).`, severity: 'warning'});
    }

    const uploadedImages = files.slice(0, remainingSlots).map(file => ({
      file,
      preview: URL.createObjectURL(file),
      id: uuidv4(),
      isNew: true
    }));

    setNewImages(prev => [...prev, ...uploadedImages]);
  };

  const handleRemoveImage = (id, isExisting) => {
    if (isExisting) {
      // Mark existing image for deletion
      const imageToRemove = images.find(img => img === id);
      if (imageToRemove) {
        setDeletedImages(prev => [...prev, imageToRemove]);
      }
      setImages(prev => prev.filter(img => img !== id));
    } else {
      // Remove newly uploaded image
      const imageToRemove = newImages.find(img => img.id === id);
      if (imageToRemove) {
        URL.revokeObjectURL(imageToRemove.preview);
      }
      setNewImages(prev => prev.filter(img => img.id !== id));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'category') {
      setFormData(prev => ({
        ...prev,
        category: value,
        subcategory: '',
        ...Object.keys(categoryFields[prev.subcategory] || {}).reduce((acc, key) => {
          acc[key] = '';
          return acc;
        }, {})
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    
    try {
      // 1. Upload new images
      const uploadedImageUrls = await Promise.all(
        newImages.map(async (img) => {
          const imageRef = ref(storage, `listings/${auth.currentUser.uid}/${uuidv4()}-${img.file.name}`);
          await uploadBytes(imageRef, img.file);
          return await getDownloadURL(imageRef);
        })
      );

      // 2. Combine existing (non-deleted) images with new ones
      const finalImages = [
        ...images.filter(img => !deletedImages.includes(img)),
        ...uploadedImageUrls
      ];

      // 3. Prepare subcategory data
      const subcategoryData = {};
      if (formData.subcategory && categoryFields[formData.subcategory]) {
        categoryFields[formData.subcategory].forEach(field => {
          subcategoryData[field.name] = formData[field.name];
        });
      }

      // 4. Prepare the update data
      const updateData = {
        ...formData,
        price: Number(formData.price),
        images: finalImages,
        coverImage: finalImages[0] || null,
        updatedAt: serverTimestamp(),
        ...subcategoryData
      };

      // 5. Update the listing in Firestore
      const listingRef = doc(db, 'listings', id);
      await updateDoc(listingRef, updateData);

      setSnackbar({
        open: true,
        message: 'Listing updated successfully!',
        severity: 'success'
      });
      
      // Redirect after a short delay
      setTimeout(() => {
        navigate('/my-listings', { state: { listingUpdated: true } });
      }, 1500);

    } catch (err) {
      console.error('Error updating listing:', err);
      setSnackbar({
        open: true,
        message: 'Failed to update listing. Please try again.',
        severity: 'error'
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ 
        py: 4, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '60vh'
      }}>
        <CircularProgress size={60} />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate('/my-listings')}
          sx={{ 
            mb: 2,
            textTransform: 'none',
            color: 'text.secondary'
          }}
        >
          Back to My Listings
        </Button>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Edit Listing
          </Typography>
          <Chip 
            label={listing?.status?.replace('_', ' ') || 'Draft'} 
            color={
              listing?.status === 'active' ? 'success' : 
              listing?.status === 'pending_review' ? 'warning' : 'default'
            }
            size="small"
            variant="outlined"
          />
        </Box>
        
        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 3 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setError(null)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        )}
      </Box>

      <StyledPaper elevation={0}>
        <form onSubmit={handleSubmit}>
          {/* Image Preview Section */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
              Listing Images
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Update your listing images (max 10 total)
            </Typography>
            
            <Box sx={{
              display: 'flex',
              gap: 2,
              flexWrap: 'wrap'
            }}>
              {/* Existing images */}
              {images.map((img, index) => (
                <Box key={img} position="relative">
                  <Avatar
                    variant="rounded"
                    src={img}
                    sx={{ 
                      width: 100, 
                      height: 100,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(img, true)}
                    sx={{
                      position: 'absolute',
                      top: 4,
                      right: 4,
                      backgroundColor: 'rgba(255,255,255,0.8)'
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                  {index === 0 && (
                    <Chip 
                      label="Cover" 
                      size="small" 
                      color="primary"
                      sx={{
                        position: 'absolute',
                        bottom: 4,
                        left: 4,
                        fontSize: '0.6rem',
                        height: '20px'
                      }}
                    />
                  )}
                </Box>
              ))}
              
              {/* Newly uploaded images */}
              {newImages.map((img, index) => (
                <Box key={img.id} position="relative">
                  <Avatar
                    variant="rounded"
                    src={img.preview}
                    sx={{ 
                      width: 100, 
                      height: 100,
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(img.id, false)}
                    sx={{
                      position: 'absolute',
                      top: 4,
                      right: 4,
                      backgroundColor: 'rgba(255,255,255,0.8)'
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
              ))}
              
              {/* Upload button (only show if we have space) */}
              {(images.length + newImages.length) < 10 && (
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<PhotoCamera />}
                  sx={{
                    width: 100,
                    height: 100,
                    borderStyle: 'dashed',
                    flexDirection: 'column'
                  }}
                >
                  Add
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    accept="image/jpeg, image/png, image/webp"
                    onChange={handleImageUpload}
                  />
                </Button>
              )}
            </Box>
          </Box>
          
          <Divider sx={{ my: 3 }} />
          
          {/* Basic Info Section */}
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
            Basic Information
          </Typography>
          
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <FullWidthTextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
                InputProps={{
                  startAdornment: (
                    <Description color="action" />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FullWidthFormControl variant="outlined" required>
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  startAdornment={<Category color="action" />}
                >
                  <MenuItem value="" disabled><em>Select a category</em></MenuItem>
                  {categories.map(cat => (
                    <MenuItem key={cat.name} value={cat.name}>{cat.name}</MenuItem>
                  ))}
                </Select>
              </FullWidthFormControl>
            </Grid>
          </Grid>

          <FullWidthFormControl variant="outlined" required disabled={!formData.category}>
            <InputLabel>Subcategory</InputLabel>
            <Select
              label="Subcategory"
              name="subcategory"
              value={formData.subcategory}
              onChange={handleInputChange}
              disabled={!formData.category}
            >
              <MenuItem value="" disabled><em>Select a subcategory</em></MenuItem>
              {formData.category && categories
                .find(cat => cat.name === formData.category)
                ?.subcategories.map(sub => (
                  <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                ))}
            </Select>
          </FullWidthFormControl>
          
          <FullWidthTextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            multiline
            rows={6}
            required
            sx={{ mb: 3 }}
          />
          
          <Divider sx={{ my: 3 }} />
          
          {/* Pricing Section */}
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
            Pricing & Details
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FullWidthTextField
                label="Price (TSh)"
                name="price"
                type="number"
                value={formData.price}
                onChange={handleInputChange}
                required
                InputProps={{
                  startAdornment: (
                    <AttachMoney color="action" />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.negotiable}
                    onChange={handleInputChange}
                    name="negotiable"
                    color="primary"
                  />
                }
                label="Price is negotiable"
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>

          <FullWidthTextField
            label="Location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            required
            sx={{ mt: 2 }}
          />
          
          {formData.subcategory && categoryFields[formData.subcategory] && (
            <>
              <Divider sx={{ my: 3 }} />
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                {formData.subcategory} Details
              </Typography>
              <CategoryInputs
                category={formData.subcategory}
                formData={formData}
                handleInputChange={handleInputChange}
                TextFieldComponent={FullWidthTextField}
                FormControlComponent={FullWidthFormControl}
              />
            </>
          )}
          
          <Divider sx={{ my: 4 }} />
          
          {/* Action Buttons */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 2,
            pt: 2
          }}>
            <StyledButton
              variant="outlined"
              onClick={() => navigate('/my-listings')}
              sx={{ 
                px: 4,
                borderRadius: '12px',
                textTransform: 'none'
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={updating}
              startIcon={!updating && <Save />}
              sx={{ 
                px: 4,
                borderRadius: '12px',
                textTransform: 'none'
              }}
            >
              {updating ? <CircularProgress size={24} /> : 'Save Changes'}
            </StyledButton>
          </Box>
        </form>
      </StyledPaper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%', borderRadius: 2 }}
          elevation={6}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditListing;