import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard'; // adjust path if needed

const ProductCardWrapper = ({ product, ...rest }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ProductCard
      product={product}
      loading={loading}
      {...rest}
    />
  );
};

export default ProductCardWrapper;
