import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme,
  Box,
  Avatar,
  ListItemIcon,
  Badge,
  Chip,
  Container
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../assets/logo.png";
import { collection, getDocs, query, limit, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import {
  Home,
  FavoriteBorder,
  Inventory2,
  MailOutline,
  Search,
  Logout,
  PersonOutline,
  DashboardCustomize,
  AddCircleOutline,
  ShoppingBag,
  PendingActions,
  ArchiveOutlined,
  NotificationsNone
} from "@mui/icons-material";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  // Add this useEffect to track unread messages
useEffect(() => {
  if (!user) return;

  const fetchUnreadCount = async () => {
    const chatsRef = collection(db, 'chats');
    const q = query(chatsRef, where('participants', 'array-contains', user.uid));
    const chatsSnap = await getDocs(q);
    
    let totalUnread = 0;
    
    for (const doc of chatsSnap.docs) {
      const messagesRef = collection(db, 'chats', doc.id, 'messages');
      const unreadQuery = query(
        messagesRef,
        where('senderId', '!=', user.uid),
        where('read', '==', false)
      );
      const unreadSnap = await getDocs(unreadQuery);
      totalUnread += unreadSnap.size;
    }
    
    setUnreadCount(totalUnread);
  };

  const unsubscribe = auth.onAuthStateChanged((user) => {
    setUser(user);
    if (user) {
      fetchUnreadCount();
    }
  });

  return () => unsubscribe();
}, [user]);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const userMenuItems = [
    { text: "My Listings", icon: <Home fontSize="small" />, path: "/my-listings" },
    { text: "Pending Approval", icon: <PendingActions fontSize="small" />, path: "/my-listings?tab=pending" },
    { text: "Archived Listings", icon: <ArchiveOutlined fontSize="small" />, path: "/my-listings?tab=archived" },
    { text: "Favorites", icon: <FavoriteBorder fontSize="small" />, path: "/my-listings?tab=favorites" },
    { text: "Messages", icon: <MailOutline fontSize="small" />, path: "/messages", badge: unreadCount > 0, badgeContent: unreadCount },
    { text: "Saved Searches", icon: <Search fontSize="small" />, path: "/saved-searches" },
    { text: "Account", icon: <PersonOutline fontSize="small" />, path: "/account" },
    { text: "Log Out", icon: <Logout fontSize="small" />, action: handleLogout }
  ];

  const navItems = (
    <>
      <Button 
        component={Link} 
        to="/browse-listings " 
        color="inherit" 
        sx={{ 
          textTransform: 'none', 
          fontWeight: 500,
          '&:hover': {
            color: theme.palette.primary.main
          }
        }}
        startIcon={<ShoppingBag sx={{ fontSize: 20 }} />}
      >
        Browse
      </Button>
      <Button 
        component={Link} 
        to="/pricing" 
        color="inherit" 
        sx={{ 
          textTransform: 'none', 
          fontWeight: 500,
          '&:hover': {
            color: theme.palette.primary.main
          }
        }}
        startIcon={<DashboardCustomize sx={{ fontSize: 20 }} />}
      >
        Pricing
      </Button>
      {user ? (
        <>
          <IconButton
            onClick={handleMenuOpen}
            sx={{ 
              ml: 1,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Avatar 
                src={user.photoURL} 
                sx={{ 
                  width: 32, 
                  height: 32,
                  border: `2px solid ${theme.palette.primary.main}`
                }}
              >
                {user.displayName?.charAt(0)}
              </Avatar>
              {!isMobile && (
                <Typography variant="body2" fontWeight={500}>
                  {user.displayName?.split(' ')[0]}
                </Typography>
              )}
            </Box>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              elevation: 3,
              sx: {
                width: 280,
                borderRadius: '12px',
                overflow: 'visible',
                filter: 'drop-shadow(0px 4px 12px rgba(0,0,0,0.12))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem sx={{ pointerEvents: 'none', py: 2 }}>
              <ListItemIcon>
                <Avatar src={user.photoURL} />
              </ListItemIcon>
              <Box>
                <Typography variant="subtitle1" fontWeight={600}>
                  {user.displayName || user.email}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {user.email}
                </Typography>
              </Box>
            </MenuItem>
            <Divider />
            {userMenuItems.map((item) => (
              <MenuItem 
                key={item.text}
                onClick={() => {
                  handleMenuClose();
                  if (item.action) item.action();
                  else if (item.path) navigate(item.path);
                }}
                sx={{ py: 1.5 }}
              >
                <ListItemIcon sx={{ minWidth: '36px' }}>{item.icon}</ListItemIcon>
                {item.badge ? (
                  <Badge badgeContent={5} color="error" sx={{ mr: 1 }}>
                    <ListItemText 
                      primary={item.text} 
                      primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
                    />
                  </Badge>
                ) : (
                  <ListItemText 
                    primary={item.text} 
                    primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
                  />
                )}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Button 
          color="inherit" 
          sx={{ 
            textTransform: 'none', 
            fontWeight: 500,
            '&:hover': {
              color: theme.palette.primary.main
            }
          }}
          component={Link}
          to="/login"
          startIcon={<PersonOutline sx={{ fontSize: 20 }} />}
        >
          Sign In
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/create-listing"
        sx={{ 
          borderRadius: "12px",
          textTransform: 'none',
          fontWeight: 600,
          px: 3,
          ml: 2,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: `0 4px 12px ${theme.palette.primary.light}`,
          }
        }}
        startIcon={<AddCircleOutline sx={{ fontSize: 20 }} />}
      >
        Create Listing
      </Button>
    </>
  );

  const drawer = (
    <Box sx={{ width: 280 }} role="presentation">
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        bgcolor: theme.palette.primary.main,
        color: 'white'
      }}>
        {user ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar src={user.photoURL} sx={{ border: '2px solid white' }} />
            <Typography variant="subtitle1" fontWeight={500}>
              {user.displayName || user.email}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h6">Menu</Typography>
        )}
        <IconButton onClick={handleDrawerToggle} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ py: 1 }}>
        <ListItem 
          button 
          component={Link} 
          to="/browse" 
          onClick={handleDrawerToggle}
          sx={{ 
            borderRadius: '8px',
            mx: 1,
            my: 0.5,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            }
          }}
        >
          <ListItemIcon><ShoppingBag sx={{ fontSize: 22 }} /></ListItemIcon>
          <ListItemText 
            primary="Browse Listings" 
            primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
          />
        </ListItem>
        <ListItem 
          button 
          component={Link} 
          to="/pricing" 
          onClick={handleDrawerToggle}
          sx={{ 
            borderRadius: '8px',
            mx: 1,
            my: 0.5,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            }
          }}
        >
          <ListItemIcon><DashboardCustomize sx={{ fontSize: 22 }} /></ListItemIcon>
          <ListItemText 
            primary="Pricing" 
            primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
          />
        </ListItem>
        
        {user ? (
          <>
            <Typography variant="caption" sx={{ px: 3, py: 1, color: 'text.secondary' }}>
              MY ACCOUNT
            </Typography>
            {userMenuItems.map((item) => (
              <ListItem 
                key={item.text}
                button 
                onClick={() => {
                  handleDrawerToggle();
                  if (item.action) item.action();
                  else if (item.path) navigate(item.path);
                }}
                sx={{ 
                  borderRadius: '8px',
                  mx: 1,
                  my: 0.5,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '36px' }}>{item.icon}</ListItemIcon>
                {item.badge ? (
                  <Badge badgeContent={5} color="error" sx={{ mr: 1 }}>
                    <ListItemText 
                      primary={item.text} 
                      primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
                    />
                  </Badge>
                ) : (
                  <ListItemText 
                    primary={item.text} 
                    primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
                  />
                )}
              </ListItem>
            ))}
          </>
        ) : (
          <>
            <ListItem 
              button 
              component={Link} 
              to="/login" 
              onClick={handleDrawerToggle}
              sx={{ 
                borderRadius: '8px',
                mx: 1,
                my: 0.5,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                }
              }}
            >
              <ListItemIcon><PersonOutline sx={{ fontSize: 22 }} /></ListItemIcon>
              <ListItemText 
                primary="Sign In" 
                primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
              />
            </ListItem>
            <ListItem 
              button 
              component={Link} 
              to="/signup" 
              onClick={handleDrawerToggle}
              sx={{ 
                borderRadius: '8px',
                mx: 1,
                my: 0.5,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                }
              }}
            >
              <ListItemIcon><PersonOutline sx={{ fontSize: 22 }} /></ListItemIcon>
              <ListItemText 
                primary="Sign Up" 
                primaryTypographyProps={{ variant: 'body2', fontWeight: 500 }} 
              />
            </ListItem>
          </>
        )}
        
        <ListItem 
          button 
          component={Link} 
          to="/create-listing" 
          onClick={handleDrawerToggle}
          sx={{ 
            mt: 2,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            borderRadius: '12px',
            mx: 2,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            }
          }}
        >
          <ListItemIcon sx={{ color: 'white' }}><AddCircleOutline sx={{ fontSize: 22 }} /></ListItemIcon>
          <ListItemText 
            primary="Create Listing" 
            primaryTypographyProps={{ variant: 'body2', fontWeight: 600 }} 
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar 
      position="sticky" 
      sx={{ 
        bgcolor: "background.paper", 
        color: "text.primary", 
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        borderBottom: '1px solid',
        borderColor: 'divider',
        py: 0.5
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Box 
            component={Link} 
            to="/" 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              textDecoration: 'none',
              '&:hover': {
                opacity: 0.9
              }
            }}
          >
            <img 
              src={Logo}
              alt="Logo" 
              style={{ 
                height: 40,
                marginRight: 8
              }} 
            />
            {!isMobile && (
              <Typography 
                variant="h6" 
                component="div" 
                sx={{ 
                  fontWeight: 700,
                  color: theme.palette.text.primary
                }}
              >
                BeiZetu
              </Typography>
            )}
          </Box>

          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {navItems}
            </Box>
          )}

          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ color: 'text.primary' }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </Container>

      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 280,
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;