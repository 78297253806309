import React, { useState, useEffect } from 'react';
import { 
  Container, Box, Typography, Avatar, Button, 
  TextField, Divider, Paper, Grid, Tab, Tabs,
  useMediaQuery, useTheme
} from '@mui/material';
import { auth, db } from '../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Email, Phone, LocationOn, Edit, Save } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const ProfilePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const UserAccount = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    location: ''
  });
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        const docSnap = await getDoc(userRef);
        
        if (docSnap.exists()) {
          setUserData(docSnap.data());
          setFormData({
            name: docSnap.data().name || '',
            phone: docSnap.data().phone || '',
            location: docSnap.data().location || ''
          });
        }
      }
    };

    fetchUserData();
  }, []);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, formData);
      setUserData(prev => ({ ...prev, ...formData }));
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!userData) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {/* Profile Section */}
        <Grid item xs={12} md={4}>
          <ProfilePaper>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar
                src={userData.photoURL}
                sx={{ 
                  width: 120, 
                  height: 120,
                  mb: 2,
                  border: `3px solid ${theme.palette.primary.main}`
                }}
              />
              
              {isEditing ? (
                <TextField
                  fullWidth
                  label="Display Name"
                  name="displayName"
                  value={formData.name}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
              ) : (
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                  {userData.name || 'No name set'}
                </Typography>
              )}
              
              <Box sx={{ width: '100%', mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Email color="primary" sx={{ mr: 1 }} />
                  <Typography>{auth.currentUser.email}</Typography>
                </Box>
                
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    sx={{ mb: 1 }}
                    InputProps={{
                      startAdornment: <Phone color="primary" sx={{ mr: 1 }} />
                    }}
                  />
                ) : (
                  userData.phone && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Phone color="primary" sx={{ mr: 1 }} />
                      <Typography>{userData.phone}</Typography>
                    </Box>
                  )
                )}
                
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Location"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: <LocationOn color="primary" sx={{ mr: 1 }} />
                    }}
                  />
                ) : (
                  userData.location && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <LocationOn color="primary" sx={{ mr: 1 }} />
                      <Typography>{userData.location}</Typography>
                    </Box>
                  )
                )}
              </Box>
              
              <Button
                variant="contained"
                color="primary"
                startIcon={isEditing ? <Save /> : <Edit />}
                onClick={isEditing ? handleSave : handleEditToggle}
                fullWidth
                sx={{ borderRadius: '12px' }}
              >
                {isEditing ? 'Save Changes' : 'Edit Profile'}
              </Button>
            </Box>
          </ProfilePaper>
        </Grid>
        
        {/* Content Section */}
        <Grid item xs={12} md={8}>
          <ProfilePaper>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange}
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons="auto"
              sx={{ mb: 3 }}
            >
              <Tab label="Account Details" />
              <Tab label="Security" />
              <Tab label="Notifications" />
            </Tabs>
            
            <Divider sx={{ mb: 3 }} />
            
            {activeTab === 0 && (
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                  Account Information
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Member since {new Date(userData.createdAt?.toDate()).toLocaleDateString()}
                </Typography>
                
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Last Login
                    </Typography>
                    <Typography>
                      {new Date(userData.lastLogin?.toDate()).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Account Status
                    </Typography>
                    <Typography color="success.main">
                      Active
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            
            {activeTab === 1 && (
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                  Security Settings
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Manage your password and security preferences
                </Typography>
                <Button variant="outlined" color="primary">
                  Change Password
                </Button>
              </Box>
            )}
            
            {activeTab === 2 && (
              <Box>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                  Notification Preferences
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  Choose how you receive notifications
                </Typography>
                {/* Add notification settings here */}
              </Box>
            )}
          </ProfilePaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserAccount;