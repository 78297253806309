import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase";
import { AuthProvider } from "../context/AuthContext";
import SplashScreen from "../pages/SplashScreen";
import HomePage from "../pages/Home";
import Header from "../components/Header";
import ProductView from "../pages/ProductView";
import CategoryPage from "../pages/CategoryPage";
import ProductsPage from "../pages/ProductsPage";
import PostListing from "../pages/PostListing";
import PrivateRoute from "./PrivateRoute";
import Auth from "../pages/Auth";
import UserListings from "../pages/UserListings";
import UserAccount from "../pages/UserAccount";
import CategoryView from "../pages/CategoryView";
import ChatPage from "../pages/ChatPage";
import PricingPage from "../pages/PricingPage";
import EditListing from "../pages/EditListing";
import ChatListPage from "../pages/ChatListPage";
import BulkUploadButton from "../pages/BulkUploadButton";
import BrowseListings from "../pages/BrowseListings";

const MainLayout = ({ children }) => (
  <>
    <Header />
    {children}
  </>
);

const AppRoutes = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000); // Show splash for 2 seconds

    return () => clearTimeout(timer);
  }, []);

  // Wrap everything with AuthProvider
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Splash screen route - only shown once */}
          {showSplash ? (
            <Route path="/" element={<SplashScreen />} />
          ) : (
            <Route path="/" element={<Navigate to="/home" replace />} />
          )}

          {/* Public routes */}
          <Route
            path="/home"
            element={
              <MainLayout>
                <HomePage />
              </MainLayout>
            }
          />

          <Route
            path="/product/:id"
            element={
              <MainLayout>
                <ProductView />
              </MainLayout>
            }
          />

          <Route
            path="pricing"
            element={
              <MainLayout>
                <PricingPage />
              </MainLayout>
            }
          />

          <Route
            path="/products"
            element={
              <MainLayout>
                <ProductsPage />
              </MainLayout>
            }
          />

          <Route
            path="/categories"
            element={
              <MainLayout>
                <CategoryPage />
              </MainLayout>
            }
          />

          <Route
            path="/products/:category"
            element={
              <MainLayout>
                <ProductsPage />
              </MainLayout>
            }
          />

          {/* Auth routes */}
          <Route path="/login" element={<Auth isLogin={true} />} />
          <Route path="/signup" element={<Auth isLogin={false} />} />

          {/* Protected routes */}
          <Route
            path="/create-listing"
            element={
              <PrivateRoute>
                <MainLayout>
                  <PostListing />
                </MainLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/create-listing/:category"
            element={
              <PrivateRoute>
                <MainLayout>
                  <PostListing />
                </MainLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/my-listings"
            element={
              <PrivateRoute>
                <MainLayout>
                  <UserListings />
                </MainLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/account"
            element={
              <PrivateRoute>
                <MainLayout>
                  <UserAccount />
                </MainLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/chat/:recipientId"
            element={
              <PrivateRoute>
                <MainLayout>
                  <ChatPage />
                </MainLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/messages"
            element={
              <PrivateRoute>
                <MainLayout>
                  <ChatListPage />
                </MainLayout>
              </PrivateRoute>
            }
          />

          <Route
            path="/edit-listing/:id"
            element={
              <PrivateRoute>
                <MainLayout>
                  <EditListing />
                </MainLayout>
              </PrivateRoute>
            }
          />

          {/* Category view route */}
          <Route
            path="/category/:categoryName"
            element={
              <MainLayout>
                <CategoryView />
              </MainLayout>
            }
          />

          <Route
            path="/browse-listings"
            element={
              <MainLayout>
                <BrowseListings />
              </MainLayout>
            }
          />

          <Route 
          path="/bulk-upload"
          element={
            <MainLayout>
              <BulkUploadButton />
            </MainLayout>
          } />

          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default AppRoutes;
