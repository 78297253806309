// src/pages/PostListing.js
import React, { useState } from 'react';
import {
  Container, Box, Typography, Button, Paper, Grid, TextField,
  Card, CardMedia, CardActions, IconButton, Divider, FormControl,
  InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio,
  CircularProgress, Snackbar, Alert, CardContent, List, ListItem,
  ListItemText, Checkbox, Chip,
} from '@mui/material';
import { PhotoCamera, Delete, CloudUpload, Stars, AccessTime, Add } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, storage, auth} from '../config/firebase';
import ListingStepper from '../components/ListingStepper.tsx';
import CategoryInputs, { categoryFields } from '../components/CategoryInputs';
import { categories } from '../data/categories';

// --- Styled Components ---
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const PricingCard = styled(Card)(({ theme, selected }) => ({
  border: selected ? `2px solid ${theme.palette.primary.main}` : '1px solid #eaeaea',
  borderRadius: 12,
  transition: 'all 0.3s ease',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[3],
  },
  cursor: 'pointer',
  backgroundColor: '#ffffff',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '12px 24px',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
}));

const FullWidthFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
    color: theme.palette.text.secondary,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiSelect-select': {
      padding: '14px 16px',
    },
  },
}));

const FullWidthTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 16px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
    color: theme.palette.text.secondary,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}33`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 16px',
  },
}));

// --- Component Definition ---
export default function PostListing() {
  const [activeStep, setActiveStep] = useState(0);
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedPlan, setSelectedPlan] = useState('free');

  const pricingPlans = [
    {
      id: 'free',
      name: 'Free Posting',
      price: 0,
      features: [
        'Basic listing visibility',
        '7 days visibility',
        'Standard placement'
      ],
      boostDays: 7
    },
    {
      id: 'standard',
      name: 'Standard Boost',
      price: 5000,
      features: [
        'Higher visibility',
        '14 days visibility',
        'Priority placement',
        'Featured in category'
      ],
      boostDays: 14
    },
    {
      id: 'premium',
      name: 'Premium Boost',
      price: 10000,
      features: [
        'Top visibility',
        '30 days visibility',
        'Top of search results',
        'Featured on homepage'
      ],
      boostDays: 30
    }
  ];

  const steps = ['Listing Details', 'Photos', 'Promotion', 'Finish'];

  // Update the formData initialization to include price and negotiable field
  const [formData, setFormData] = useState({
    listingType: 'individual',
    title: '',
    description: '',
    category: '',
    subcategory: '',
    location: '',
    price: '',
    negotiable: false,
    promotionPlan: 'free',
    promotedUntil: null,
    status: 'pending_review',
  });

  const handleNext = async () => {
    if (activeStep === steps.length - 2) {
      await handleSubmit();
    } else if (activeStep === steps.length - 1 && !uploading && submitted) {
      handleNewListing();
    } else {
      if (activeStep === 0 && (!formData.title || !formData.description || !formData.category || !formData.subcategory || !formData.location)) {
        setSnackbar({ open: true, message: 'Please fill in all required details including location.', severity: 'warning'});
        return;
      }
      if (activeStep === 1 && images.length === 0) {
        setSnackbar({ open: true, message: 'Please upload at least one photo.', severity: 'warning'});
        return;
      }
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const currentImageCount = images.length;
    const remainingSlots = 10 - currentImageCount;

    if (files.length > remainingSlots) {
      setSnackbar({ open: true, message: `You can only upload ${remainingSlots} more images (max 10).`, severity: 'warning'});
    }

    const newImages = files.slice(0, remainingSlots).map(file => ({
      file,
      preview: URL.createObjectURL(file),
      id: uuidv4()
    }));

    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleRemoveImage = (id) => {
    const imageToRemove = images.find(img => img.id === id);
    if (imageToRemove) {
      URL.revokeObjectURL(imageToRemove.preview);
    }
    setImages(prevImages => prevImages.filter(img => img.id !== id));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'category') {
      setFormData(prev => ({
        ...prev,
        category: value,
        subcategory: '',
        ...Object.keys(categoryFields[prev.subcategory] || {}).reduce((acc, key) => {
          acc[key] = '';
          return acc;
        }, {})
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
    const selected = pricingPlans.find(p => p.id === planId);
    let promotedUntil = null;
    if (planId !== 'free' && selected) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + selected.boostDays);
      promotedUntil = expiryDate.toISOString();
    }

    setFormData(prev => ({
      ...prev,
      promotionPlan: planId,
      promotedUntil: promotedUntil
    }));
  };

  const handleSubmit = async () => {
    // Check if user is authenticated
    if (!auth.currentUser || !auth.currentUser.uid) {
      setSnackbar({
        open: true,
        message: 'You must be logged in to create a listing.',
        severity: 'error'
      });
      // Consider guiding user to login page/modal here
      return;
    }

    // Re-validate essential fields right before submission attempt
    const requiredFields = ['title', 'description', 'category', 'subcategory', 'location', 'price'];
    const missingField = requiredFields.find(field => !formData[field]);

    if (missingField) {
       setSnackbar({ open: true, message: `Missing required field: ${missingField}. Please complete all details.`, severity: 'error' });
       setActiveStep(0); // Send back to the details step
       return;
    }
     if (images.length === 0) {
       setSnackbar({ open: true, message: 'Please upload at least one photo.', severity: 'error' });
       setActiveStep(1); // Send to photo step
       return;
     }

    // Indicate processing state
    setUploading(true);
    // Ensure user is visually on the final step if they clicked 'Submit' early
    if (activeStep !== steps.length -1 ) {
        setActiveStep(steps.length - 1);
    }


    try {
      // 1. Upload Images (Improved error handling and path)
      const imageUrls = await Promise.all(
        images.map(async (img) => {
          // Ensure it's a file needing upload (and not just a preview blob URL)
          if (!img.file) return null; // Skip if no file object attached
          // Use user ID in path for better organization/rules
          const imageRef = ref(storage, `listings/${auth.currentUser.uid}/${uuidv4()}-${img.file.name}`);
          await uploadBytes(imageRef, img.file);
          return await getDownloadURL(imageRef);
        })
      );
      // Filter out any nulls (if an image failed for some reason or wasn't a file)
      const validImageUrls = imageUrls.filter(url => url !== null);

      if (validImageUrls.length === 0 && images.length > 0) {
        // Check if files existed but all uploads failed
        throw new Error("Image upload failed. Please check your connection and try again.");
      }
      if (validImageUrls.length === 0 && images.length === 0) {
        // Should have been caught earlier, but safety check
        throw new Error("No images were provided for the listing.");
      }


      // 2. Calculate Expiry Date based on Promotion Plan
      const selectedPlanData = pricingPlans.find(p => p.id === selectedPlan);
      const expiryDurationDays = selectedPlanData?.boostDays || 7; // Default to 7 days for 'free'
      const expiresAtDate = new Date();
      expiresAtDate.setDate(expiresAtDate.getDate() + expiryDurationDays);


      // 3. --- *** CORRECTED SUBCATEGORY DATA COLLECTION *** ---
      const subcategoryData = {};
      // Get the ARRAY of field definitions for the current subcategory
      const subcategoryFieldDefinitionsArray = categoryFields[formData.subcategory] || [];

      // Iterate over the ARRAY of field definition objects
      subcategoryFieldDefinitionsArray.forEach(fieldDefinition => {
        const key = fieldDefinition.name; // Get the field name (key for formData)

        // Check if formData ACTUALLY has a value for this key
        // Handles text, numbers, selects, radios directly.
        // Handles single 'false' boolean checkbox (it won't be undefined/null).
        // Handles empty arrays from multi-checkbox/tags if they are valid (they won't be undefined/null).
        if (formData[key] !== undefined && formData[key] !== null) {

          // Add the value from formData to our subcategory data object
          subcategoryData[key] = formData[key];

          // Optional: Add specific type conversions here if necessary
          // Example: Ensure numbers are saved as numbers, handle potential empty strings for number fields
          // if (fieldDefinition.type === 'number' && typeof formData[key] !== 'number') {
          //   subcategoryData[key] = Number(formData[key]) || (fieldDefinition.min !== undefined ? fieldDefinition.min : 0); // Default to 0 or min if conversion fails
          // } else {
          //   subcategoryData[key] = formData[key];
          // }
        }
      });
      // --- *** END OF CORRECTED SUBCATEGORY LOGIC *** ---


      // 4. Prepare the final listing data object for Firestore
      const listingData = {
        // Basic Information (Trim strings, ensure number type for price)
        title: formData.title.trim(),
        description: formData.description.trim(),
        category: formData.category,
        subcategory: formData.subcategory,
        location: formData.location.trim(),
        price: Number(formData.price) || 0, // Ensure price is a number, default 0
        negotiable: Boolean(formData.negotiable), // Ensure negotiable is boolean

        // --- Include the collected subcategory-specific fields ---
        ...subcategoryData,

        // User Information
        userId: auth.currentUser.uid,
        userEmail: auth.currentUser.email || null, // Include email if available
        userName: auth.currentUser.displayName || null, // Include name if available

        // Listing Details
        listingType: formData.listingType,
        status: 'pending_review', // Initial status before approval
        isPromoted: selectedPlan !== 'free',
        promotionPlan: selectedPlan,
        views: 0, // Initialize view count

        // Timestamps
        createdAt: serverTimestamp(), // Use server's timestamp
        updatedAt: serverTimestamp(), // Use server's timestamp
        expiresAt: expiresAtDate.toISOString(), // Store expiry date as ISO string

        // Media
        images: validImageUrls, // Array of successfully uploaded image URLs
        coverImage: validImageUrls[0] || null, // Use first image as cover (or null if none)
      };

      // 5. Submit to Firestore
      console.log("Submitting listing data:", listingData); // Log before sending
      const docRef = await addDoc(collection(db, 'listings'), listingData);
      console.log("Document written with ID: ", docRef.id);

      setSnackbar({
        open: true,
        message: 'Listing submitted successfully! It is now under review.',
        severity: 'success'
      });
      setSubmitted(true); // Update UI state to show success message

    } catch (error) {
      console.error('Error submitting listing:', error);
      // Provide more specific feedback if possible
      let errorMessage = 'Error submitting listing. Please try again.';
      if (error.message.includes("Image upload failed")) {
          errorMessage = error.message; // Show specific upload error
      } else if (error.code) {
          // Handle potential Firestore errors (e.g., permission denied)
          errorMessage = `Error: ${error.code} - ${error.message}`;
      } else {
          errorMessage = `An unexpected error occurred: ${error.message || 'Unknown error'}`;
      }

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
      // Keep user on the last step to allow retry without losing data / re-uploading
      setActiveStep(steps.length - 1); // Ensure they stay on the review/submit step

    } finally {
      setUploading(false); // Ensure loading indicator is turned off
    }
  };


  const handleNewListing = () => {
    setActiveStep(0);
    setImages([]);
    setFormData({
      listingType: 'individual',
      title: '',
      description: '',
      category: '',
      subcategory: '',
      location: '',
      price: '',
      negotiable: false,
      promotionPlan: 'free',
      promotedUntil: null,
      status: 'pending_review',
    });
    setSelectedPlan('free');
    setSubmitted(false);
    setUploading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
              Listing Information
            </Typography>

            <FullWidthFormControl component="fieldset" sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight={500} gutterBottom sx={{ mb: 1 }}>
                Listing Type
              </Typography>
              <RadioGroup
                row
                name="listingType"
                value={formData.listingType}
                onChange={handleInputChange}
                sx={{ gap: 2 }}
              >
                <FormControlLabel
                  value="individual"
                  control={<Radio color="primary" />}
                  label="Individual"
                  sx={{ mr: 3 }}
                />
                <FormControlLabel
                  value="professional"
                  control={<Radio color="primary" />}
                  label="Professional"
                />
              </RadioGroup>
            </FullWidthFormControl>

            <FullWidthTextField
              label="Listing Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              variant="outlined"
              required
            />

            <FullWidthTextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              variant="outlined"
              required
            />

            <FullWidthFormControl variant="outlined" required>
              <InputLabel>Category</InputLabel>
              <Select
                label="Category"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
              >
                <MenuItem value="" disabled><em>Select a category</em></MenuItem>
                {categories.map(cat => (
                  <MenuItem key={cat.name} value={cat.name}>{cat.name}</MenuItem>
                ))}
              </Select>
            </FullWidthFormControl>

            <FullWidthFormControl variant="outlined" required disabled={!formData.category}>
              <InputLabel>Subcategory</InputLabel>
              <Select
                label="Subcategory"
                name="subcategory"
                value={formData.subcategory}
                onChange={handleInputChange}
                disabled={!formData.category}
              >
                <MenuItem value="" disabled><em>Select a subcategory</em></MenuItem>
                {formData.category && categories
                  .find(cat => cat.name === formData.category)
                  ?.subcategories.map(sub => (
                    <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                  ))}
              </Select>
            </FullWidthFormControl>

            <FullWidthTextField
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              variant="outlined"
              required
            />

            <FullWidthTextField
              label="Price (TSh)"
              name="price"
              type="number"
              value={formData.price}
              onChange={handleInputChange}
              variant="outlined"
              required
              sx={{ mt: 2 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.negotiable}
                  onChange={handleInputChange}
                  name="negotiable"
                  color="primary"
                />
              }
              label="Price is negotiable"
              sx={{ mt: 1, mb: 2 }}
            />

            {formData.subcategory && categoryFields[formData.subcategory] && (
              <>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                  {formData.subcategory} Details
                </Typography>
                <CategoryInputs
                  category={formData.subcategory}
                  formData={formData}
                  handleInputChange={handleInputChange}
                  TextFieldComponent={FullWidthTextField}
                  FormControlComponent={FullWidthFormControl}
                />
              </>
            )}
          </Box>
        );

      case 1:
        return (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
              Upload Photos
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 3 }}>
              Add high-quality photos (up to 10 images). The first image will be the cover.
            </Typography>

            <StyledButton
              component="label"
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
              disabled={images.length >= 10 || uploading}
              sx={{ mb: 3 }}
            >
              {images.length >= 10 ? 'Maximum images reached' : 'Upload Images'}
              <VisuallyHiddenInput
                type="file"
                multiple
                accept="image/jpeg, image/png, image/webp"
                onChange={handleImageUpload}
                disabled={images.length >= 10 || uploading}
              />
            </StyledButton>

            <Grid container spacing={2}>
              {images.map((img, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={img.id}>
                  <Card sx={{ 
                    borderRadius: 2, 
                    overflow: 'hidden', 
                    position: 'relative',
                    width: '100%'
                  }}>
                    {index === 0 && (
                      <Chip label="Cover" size="small" color="primary" sx={{ position: 'absolute', top: 8, left: 8, zIndex: 1, color: 'white' }} />
                    )}
                    <CardMedia
                      component="img"
                      height="140"
                      image={img.preview}
                      alt={`Upload preview ${index + 1}`}
                      sx={{ 
                        objectFit: 'cover',
                        width: '100%'
                      }}
                    />
                    <CardActions sx={{ justifyContent: 'center', p: 0.5, backgroundColor: 'rgba(0,0,0,0.02)' }}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveImage(img.id)}
                        size="small"
                        aria-label={`Remove image ${index + 1}`}
                        disabled={uploading}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        );

      case 2:
        return (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
              Boost Your Listing (Optional)
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 3 }}>
              Select a promotion plan to increase visibility, or continue with a free listing.
            </Typography>

            <Grid container spacing={3}>
              {pricingPlans.map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <PricingCard
                    selected={selectedPlan === plan.id}
                    onClick={() => handlePlanSelect(plan.id)}
                    elevation={selectedPlan === plan.id ? 4 : 1}
                  >
                    <CardContent sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6" component="div" fontWeight={600}>
                          {plan.name}
                        </Typography>
                        {selectedPlan === plan.id && (
                          <Stars color="primary" />
                        )}
                      </Box>

                      <Typography variant="h4" sx={{ my: 2, fontWeight: 700 }}>
                        {plan.price > 0 ? `TSh ${plan.price.toLocaleString()}` : 'Free'}
                      </Typography>

                      <List dense sx={{ py: 0, flexGrow: 1 }}>
                        {plan.features.map((feature, index) => (
                          <ListItem key={index} sx={{ px: 0, py: 0.5 }}>
                            <ListItemText
                              primary={feature}
                              primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                            />
                          </ListItem>
                        ))}
                      </List>

                      <Typography variant="caption" display="block" color="text.secondary" sx={{ mt: 2 }}>
                        {plan.boostDays} days visibility
                      </Typography>
                    </CardContent>
                  </PricingCard>
                </Grid>
              ))}
            </Grid>
            {selectedPlan !== 'free' && (
              <Box mt={4} p={2} border="1px dashed grey" borderRadius={2}>
                <Typography variant="body1" fontWeight="medium">Payment Section (Placeholder)</Typography>
                <Typography variant="body2" color="text.secondary">Payment integration for {pricingPlans.find(p => p.id === selectedPlan)?.name} (TSh {pricingPlans.find(p => p.id === selectedPlan)?.price.toLocaleString()}) would go here.</Typography>
              </Box>
            )}
          </Box>
        );

      case 3:
        return (
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            {uploading ? (
              <>
                <CircularProgress size={60} thickness={4} sx={{ color: 'primary.main', mb: 3 }} />
                <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.secondary' }}>
                  Submitting your listing... Please wait.
                </Typography>
              </>
            ) : submitted ? (
              <>
                <Box sx={{
                  bgcolor: 'success.light',
                  color: 'success.contrastText',
                  p: { xs: 2, md: 4 },
                  borderRadius: 3,
                  mb: 3,
                  maxWidth: 600,
                  mx: 'auto',
                  border: `1px solid success.main`
                }}>
                  <AccessTime sx={{ fontSize: 60, mb: 2 }} />
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 700 }}>
                    Listing Submitted Successfully!
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3 }}>
                    Your listing is now under review. It usually takes a few hours. We'll notify you once it's approved and live.
                  </Typography>
                  <Chip
                    label="Status: Pending Review"
                    variant="filled"
                    color="warning"
                    sx={{
                      fontWeight: 600,
                      fontSize: '0.9rem',
                      padding: '4px 12px',
                      color: 'common.black',
                      backgroundColor: 'warning.light'
                    }}
                  />
                </Box>

                <StyledButton
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={handleNewListing}
                  sx={{ mt: 2 }}
                >
                  Create Another Listing
                </StyledButton>
              </>
            ) : (
              <>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 700, mb: 1 }}>
                  Ready to Submit?
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                  Review your listing details below. Click "Submit for Review" to proceed.
                </Typography>

                <Box sx={{
                  bgcolor: 'background.default',
                  p: 3,
                  borderRadius: 3,
                  textAlign: 'left',
                  maxWidth: 600,
                  mx: 'auto',
                  border: '1px solid #eee',
                  mb: 4
                }}>
                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, borderBottom: '1px solid #eee', pb: 1 }}>
                    Listing Summary
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" color="text.secondary" display="block">Title</Typography>
                      <Typography variant="body1" fontWeight="medium">{formData.title || '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" color="text.secondary" display="block">Category</Typography>
                      <Typography variant="body1" fontWeight="medium">{formData.category || '-'} / {formData.subcategory || '-'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" color="text.secondary" display="block">Description</Typography>
                      <Typography variant="body2" sx={{ maxHeight: 100, overflow: 'auto' }}>{formData.description || '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" color="text.secondary" display="block">Price</Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {formData.price ? `TSh ${formData.price.toLocaleString()}` : '-'}
                        {formData.negotiable && ' (Negotiable)'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" color="text.secondary" display="block">Images</Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {images.length} {images.length === 1 ? 'image' : 'images'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" color="text.secondary" display="block">Location</Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {formData.location || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="caption" color="text.secondary" display="block">Promotion Plan</Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {pricingPlans.find(p => p.id === selectedPlan)?.name || 'Free Posting'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={uploading}
                  endIcon={uploading ? <CircularProgress size={20} color="inherit" /> : null}
                >
                  {uploading ? 'Processing...' : 'Submit for Review'}
                </StyledButton>
              </>
            )}
          </Box>
        );

      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 2, md: 4 } }}>
      <Paper elevation={0} sx={{
        p: { xs: 2, sm: 3, md: 4 },
        borderRadius: 4,
        bgcolor: 'background.paper',
        boxShadow: '0 8px 32px rgba(0,0,0,0.05)'
      }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{
          fontWeight: 700,
          mb: 4,
          color: 'text.primary',
          textAlign: 'center'
        }}>
          Create New Listing
        </Typography>

        <ListingStepper activeStep={activeStep} steps={steps} sx={{ mb: 4 }} />

        <Divider sx={{ my: 3 }} />

        <Box sx={{ minHeight: 300 }}>
          {getStepContent(activeStep)}
        </Box>

        {!uploading && !submitted && activeStep < steps.length -1 && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 4,
            pt: 3,
            borderTop: '1px solid #eee',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            gap: 2
          }}>
            <StyledButton
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              sx={{
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              Back
            </StyledButton>

            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={
                (activeStep === 0 && (!formData.title || !formData.description || !formData.category || !formData.subcategory || !formData.location || !formData.price)) ||
                (activeStep === 1 && images.length === 0)
              }
              sx={{
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              {activeStep === steps.length - 2 ? 'Submit for Review' : 'Next'}
            </StyledButton>
          </Box>
        )}

        {activeStep === steps.length -1 && !uploading && !submitted && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, pt: 3, borderTop: '1px solid #eee' }}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={uploading}
              endIcon={uploading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {uploading ? 'Processing...' : 'Submit for Review'}
            </StyledButton>
          </Box>
        )}

      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%', borderRadius: 2 }}
          elevation={6}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}