import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Box, 
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Grid
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { categories } from '../data';
import ProductCardWrapper from '../components/ProductCardWrapper';

const CategoryPage = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [savedItems, setSavedItems] = useState({});
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const category = categories.find(c => c.id === parseInt(id));

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const productsQuery = query(
          collection(db, 'listings'),
          where('category', '==', parseInt(id))
        );
        const productsSnapshot = await getDocs(productsQuery);
        const productsData = productsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCategoryProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to load products. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchProducts();
    }
  }, [id]);

  const handleSaveClick = (productId, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSavedItems(prev => ({ ...prev, [productId]: !prev[productId] }));
  };

  const formatPrice = (price) => {
    if (!price) return "Negotiable";
    return new Intl.NumberFormat('en-TZ', {
      style: 'currency',
      currency: 'TZS',
      maximumFractionDigits: 0
    }).format(price);
  };

  const getTimeAgo = (timestamp) => {
    if (!timestamp?.seconds) return 'Date not specified';
  
    const createdDate = new Date(timestamp.seconds * 1000);
    const now = new Date();
    const diffInSeconds = Math.floor((now - createdDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 30 * 86400) return `${Math.floor(diffInSeconds / 86400)} days ago`;
  
    return createdDate.toLocaleDateString();
  };

  if (!category) {
    return (
      <Container sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h5">Category not found</Typography>
        <Button 
          component={Link} 
          to="/" 
          startIcon={<ArrowBack />}
          sx={{ mt: 2 }}
        >
          Go back to home
        </Button>
      </Container>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ py: 4, textAlign: 'center' }}>
        <Typography color="error" variant="h6" sx={{ mb: 2 }}>
          Error loading products
        </Typography>
        <Typography color="text.secondary" sx={{ mb: 2 }}>
          {error}
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => window.location.reload()}
        >
          Try Again
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Button
          component={Link}
          to="/categories"
          startIcon={<ArrowBack />}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h4" component="h1" sx={{ display: 'flex', alignItems: 'center' }}>
          {category.icon} <Box component="span" sx={{ ml: 1 }}>{category.name}</Box>
        </Typography>
      </Box>

      {categoryProducts.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
          No products found in this category
        </Typography>
      ) : (
        <Grid container spacing={isMobile ? 2 : 3}>
          {categoryProducts.map((product) => (
            <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
              <ProductCardWrapper
                product={product}
                isMobile={isMobile}
                savedItems={savedItems}
                handleSaveClick={handleSaveClick}
                category={category}
                getTimeAgo={getTimeAgo}
                formatPrice={formatPrice}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default CategoryPage;