import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  CircularProgress,
  Drawer,
  Divider,
  TextField,
  Chip,
  IconButton,
  useMediaQuery,
  Slider,
  InputAdornment,
  Badge
} from "@mui/material";
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Close as CloseIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  LocationOn as LocationOnIcon,
  Work as WorkIcon,
  Star as StarIcon,
  Diamond as DiamondIcon,
} from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useAuth } from "../context/AuthContext";
import ProductCardWrapper from "../components/ProductCardWrapper";

const BrowseListings = () => {
  const { currentUser } = useAuth();
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savedItems, setSavedItems] = useState({});
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Filter states
  const [priceRange, setPriceRange] = useState([0, 10000000]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Boost types
  const BOOST_TYPES = {
    STANDARD: "standard",
    PREMIUM: "premium",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories
        const categoriesQuery = query(collection(db, "categories"));
        const categoriesSnapshot = await getDocs(categoriesQuery);
        const categoriesData = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setCategories(categoriesData);

        // Fetch all listings
        const listingsQuery = query(collection(db, "listings"));
        const listingsSnapshot = await getDocs(listingsQuery);
        const listingsData = listingsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setListings(listingsData);
        setFilteredListings(listingsData);

        // Extract unique locations
        const uniqueLocations = [
          ...new Set(listingsData.map((item) => item.location).filter(Boolean)),
        ];
        setLocations(uniqueLocations);

        // Fetch saved items if user is logged in
        if (currentUser) {
          const savedRef = doc(db, "savedItems", currentUser.uid);
          const savedSnap = await getDoc(savedRef);
          if (savedSnap.exists()) {
            setSavedItems(savedSnap.data().items || {});
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    applyFilters();
  }, [selectedCategories, selectedLocations, priceRange, searchQuery, listings]);

  const applyFilters = () => {
    let filtered = [...listings];

    // Apply category filter
    if (selectedCategories.length > 0) {
      const categoryNameMap = categories.reduce((acc, cat) => {
        acc[cat.id] = cat.name;
        return acc;
      }, {});

      filtered = filtered.filter((item) => {
        if (!item.category) return false;

        return selectedCategories.some(catId => {
          const selectedCategoryName = categoryNameMap[catId];

          // Check if item's category field matches:
          // 1. The selected Category ID (Handles case where you might store IDs later)
          // OR
          // 2. The selected Category Name (Handles your current data)
          return (
            item.category === catId ||
            (selectedCategoryName && item.category.toLowerCase() === selectedCategoryName.toLowerCase())
          );
        });
      });
    }

    // Apply location filter
    if (selectedLocations.length > 0) {
        filtered = filtered.filter(item =>
            item.location &&
            selectedLocations.some(loc =>
                item.location.toLowerCase().includes(loc.toLowerCase())
            )
        );
    }

    // Apply price filter - **UPDATED to handle string prices**
    filtered = filtered.filter((item) => {
      // Skip if price is missing or explicitly null/undefined
      if (item.price === undefined || item.price === null || item.price === '') return true; // Or false if you want to exclude items without price

      // Attempt to convert price to number
      const priceAsNumber = Number(item.price);

      // Check if conversion was successful and within range
      return (
        !isNaN(priceAsNumber) && // Ensure it's a valid number
        priceAsNumber >= priceRange[0] &&
        priceAsNumber <= priceRange[1]
      );
    });


    // Apply search query
    if (searchQuery) {
      const queryLower = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.title?.toLowerCase().includes(queryLower) ||
          item.description?.toLowerCase().includes(queryLower)
      );
    }

    setFilteredListings(filtered);
  };

  const handleSaveClick = async (id, e) => {
    e.stopPropagation();
    if (!currentUser) return;

    try {
      const savedRef = doc(db, "savedItems", currentUser.uid);
      const newSavedItems = { ...savedItems };
      newSavedItems[id] = !newSavedItems[id];
      await setDoc(savedRef, { items: newSavedItems });
      setSavedItems(newSavedItems);
    } catch (error) {
      console.error("Error saving item:", error);
    }
  };

  const formatPrice = (price) => {
    if (!price) return "Negotiable";
    return new Intl.NumberFormat("en-TZ", {
      style: "currency",
      currency: "TZS",
      maximumFractionDigits: 0,
    }).format(price);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate();
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  };

  const getTimeAgo = (timestamp) => {
    if (!timestamp?.seconds) return 'Date not specified';
  
    const createdDate = new Date(timestamp.seconds * 1000);
    const now = new Date();
    const diffInSeconds = Math.floor((now - createdDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 30 * 86400) return `${Math.floor(diffInSeconds / 86400)} days ago`;
  
    return createdDate.toLocaleDateString(); // fallback for older dates
  };

  const handleCategoryToggle = (categoryId) => {
    setSelectedCategories(prev => 
      prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleLocationToggle = (location) => {
    setSelectedLocations(prev =>
      prev.includes(location)
        ? prev.filter(loc => loc !== location)
        : [...prev, location]
    );
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const clearFilters = () => {
    setSelectedCategories([]);
    setSelectedLocations([]);
    setPriceRange([0, 10000000]);
    setSearchQuery("");
  };

  const getBoostBorderColor = (boostType) => {
    switch (boostType) {
      case BOOST_TYPES.STANDARD: return "#1976d2"; // Blue
      case BOOST_TYPES.PREMIUM: return "#9c27b0"; // Pink
      default: return "#e0e0e0";
    }
  };

  const getBoostBadge = (boostType) => {
    switch (boostType) {
      case BOOST_TYPES.STANDARD:
        return <StarIcon style={{ color: "#1976d2", fontSize: "1rem" }} />;
      case BOOST_TYPES.PREMIUM:
        return <DiamondIcon style={{ color: "#9c27b0", fontSize: "1rem" }} />;
      default: return null;
    }
  };

  const renderCategoryChips = (listing) => {
    if (!listing.category) return null;
    
    if (listing.category === "Jobs") {
      return (
        <div style={{ marginBottom: "8px" }}>
          <span style={{
            display: "inline-block",
            padding: "4px 8px",
            background: "#f5f5f5",
            borderRadius: "4px",
            fontSize: "0.7rem",
            marginRight: "4px",
            color: "#424242"
          }}>
            {listing.listingType === "individual" ? "Individual" : "Company"}
          </span>
          {listing.subcategory && (
            <span style={{
              display: "inline-block",
              padding: "4px 8px",
              background: "#f5f5f5",
              borderRadius: "4px",
              fontSize: "0.7rem",
              color: "#424242"
            }}>
              {listing.subcategory}
            </span>
          )}
        </div>
      );
    }
    
    const category = categories.find(cat => cat.id === listing.category);
    return (
      <span style={{
        padding: "4px 8px",
        background: "#f5f5f5",
        borderRadius: "4px",
        fontSize: "0.7rem",
        color: "#424242"
      }}>
        {category?.name || listing.category}
      </span>
    );
  };

  if (loading) {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ maxWidth: "100%", padding: "16px" }}>
      <div style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "24px"
      }}>
        {/* Desktop Filters */}
        {!isMobile && (
          <div style={{ width: "300px", flexShrink: 0 }}>
            <div style={{
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
              position: "sticky",
              top: "16px"
            }}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px"
              }}>
                <h3 style={{ margin: 0 }}>Filters</h3>
                <button 
                  onClick={clearFilters}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#1976d2",
                    cursor: "pointer",
                    fontSize: "14px"
                  }}
                >
                  Clear all
                </button>
              </div>

              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search listings..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "#757575" }} />,
                  style: { borderRadius: "8px" }
                }}
                style={{ marginBottom: "24px" }}
              />

              <h4 style={{ marginBottom: "8px" }}>Price Range (TZS)</h4>
              <Slider
                value={priceRange}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                min={0}
                max={10000000}
                step={100000}
                valueLabelFormat={(value) => formatPrice(value)}
                style={{ marginBottom: "24px", color: "#1976d2" }}
              />

              <h4 style={{ marginBottom: "8px" }}>Categories</h4>
              <div style={{ maxHeight: "300px", overflowY: "auto", marginBottom: "16px" }}>
                {categories.map((category) => (
                  <button
                    key={category.id}
                    onClick={() => handleCategoryToggle(category.id)}
                    style={{
                      width: "100%",
                      padding: "8px 12px",
                      marginBottom: "8px",
                      borderRadius: "4px",
                      border: "1px solid",
                      borderColor: selectedCategories.includes(category.id) ? "#1976d2" : "#e0e0e0",
                      background: selectedCategories.includes(category.id) ? "#e3f2fd" : "#fff",
                      color: selectedCategories.includes(category.id) ? "#1976d2" : "#424242",
                      textAlign: "left",
                      cursor: "pointer",
                      transition: "all 0.2s"
                    }}
                  >
                    <span>{category.name}</span>
                  </button>
                ))}
              </div>

              <Divider style={{ margin: "16px 0" }} />

              <h4 style={{ marginBottom: "8px" }}>Locations</h4>
              <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                {locations.slice(0, 10).map((location) => (
                  <button
                    key={location}
                    onClick={() => handleLocationToggle(location)}
                    style={{
                      width: "100%",
                      padding: "8px 12px",
                      marginBottom: "8px",
                      borderRadius: "4px",
                      border: "1px solid",
                      borderColor: selectedLocations.includes(location) ? "#1976d2" : "#e0e0e0",
                      background: selectedLocations.includes(location) ? "#e3f2fd" : "#fff",
                      color: selectedLocations.includes(location) ? "#1976d2" : "#424242",
                      textAlign: "left",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      transition: "all 0.2s"
                    }}
                  >
                    <LocationOnIcon style={{ 
                      fontSize: "1rem", 
                      marginRight: "8px", 
                      color: "#757575" 
                    }} />
                    <span>{location}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Mobile Filters Button */}
        {isMobile && (
          <button
            onClick={() => setMobileFiltersOpen(true)}
            style={{
              padding: "8px 16px",
              border: "1px solid #e0e0e0",
              borderRadius: "4px",
              background: "#fff",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              alignSelf: "flex-start",
              marginBottom: "16px"
            }}
          >
            <FilterIcon style={{ fontSize: "1rem" }} />
            <span>Filters</span>
          </button>
        )}

        {/* Mobile Filters Drawer */}
        <Drawer
          anchor="left"
          open={mobileFiltersOpen}
          onClose={() => setMobileFiltersOpen(false)}
          PaperProps={{
            style: { width: "280px" }
          }}
        >
          <div style={{ padding: "16px" }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px"
            }}>
              <h3 style={{ margin: 0 }}>Filters</h3>
              <IconButton onClick={() => setMobileFiltersOpen(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <Divider style={{ marginBottom: "16px" }} />
            
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search listings..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon style={{ color: "#757575" }} />,
                style: { borderRadius: "8px" }
              }}
              style={{ marginBottom: "24px" }}
            />

            <h4 style={{ marginBottom: "8px" }}>Price Range (TZS)</h4>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={10000000}
              step={100000}
              valueLabelFormat={(value) => formatPrice(value)}
              style={{ marginBottom: "24px", color: "#1976d2" }}
            />

            <h4 style={{ marginBottom: "8px" }}>Categories</h4>
            <div style={{ maxHeight: "300px", overflowY: "auto", marginBottom: "16px" }}>
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => handleCategoryToggle(category.id)}
                  style={{
                    width: "100%",
                    padding: "8px 12px",
                    marginBottom: "8px",
                    borderRadius: "4px",
                    border: "1px solid",
                    borderColor: selectedCategories.includes(category.id) ? "#1976d2" : "#e0e0e0",
                    background: selectedCategories.includes(category.id) ? "#e3f2fd" : "#fff",
                    color: selectedCategories.includes(category.id) ? "#1976d2" : "#424242",
                    textAlign: "left",
                    cursor: "pointer",
                    transition: "all 0.2s"
                  }}
                >
                  <span>{category.name}</span>
                </button>
              ))}
            </div>

            <Divider style={{ margin: "16px 0" }} />

            <h4 style={{ marginBottom: "8px" }}>Locations</h4>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {locations.slice(0, 10).map((location) => (
                <button
                  key={location}
                  onClick={() => handleLocationToggle(location)}
                  style={{
                    width: "100%",
                    padding: "8px 12px",
                    marginBottom: "8px",
                    borderRadius: "4px",
                    border: "1px solid",
                    borderColor: selectedLocations.includes(location) ? "#1976d2" : "#e0e0e0",
                    background: selectedLocations.includes(location) ? "#e3f2fd" : "#fff",
                    color: selectedLocations.includes(location) ? "#1976d2" : "#424242",
                    textAlign: "left",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    transition: "all 0.2s"
                  }}
                >
                  <LocationOnIcon style={{ 
                    fontSize: "1rem", 
                    marginRight: "8px", 
                    color: "#757575" 
                  }} />
                  <span>{location}</span>
                </button>
              ))}
            </div>
          </div>
        </Drawer>

        {/* Main Content */}
        <div style={{ flexGrow: 1 }}>
          <h1 style={{ marginBottom: "24px" }}>
            Browse Listings
            {filteredListings.length > 0 && (
              <span style={{ 
                marginLeft: "8px",
                color: "#757575",
                fontSize: "1rem",
                fontWeight: "normal"
              }}>
                ({filteredListings.length} results)
              </span>
            )}
          </h1>

          {filteredListings.length === 0 ? (
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "64px 0",
              textAlign: "center"
            }}>
              <h3 style={{ marginBottom: "16px" }}>No listings found</h3>
              <p style={{ color: "#757575", marginBottom: "16px" }}>
                Try adjusting your filters or search query
              </p>
              <button
                onClick={clearFilters}
                style={{
                  padding: "8px 16px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  background: "#fff",
                  color: "#1976d2",
                  cursor: "pointer"
                }}
              >
                Clear all filters
              </button>
            </div>
          ) : (
            <div style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
              gap: isMobile ? "8px" : "16px"
            }}>
              {filteredListings.map((listing) => {
                const category = categories.find(cat => cat.id === listing.category);
                
                return (
                  <ProductCardWrapper
                    key={listing.id}
                    product={listing}
                    isMobile={isMobile}
                    savedItems={savedItems}
                    handleSaveClick={handleSaveClick}
                    category={category}
                    getTimeAgo={getTimeAgo}
                    formatPrice={formatPrice}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrowseListings;