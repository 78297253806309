import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";  // Add this import

const firebaseConfig = {
  apiKey: "AIzaSyDEwYVK0CNiWvIS9-FcQLw1tvHsH_FN3t0",
  authDomain: "c2c-beiizetu.firebaseapp.com",
  projectId: "c2c-beiizetu",
  storageBucket: "c2c-beiizetu.firebasestorage.app",
  messagingSenderId: "741757291955",
  appId: "1:741757291955:web:858affe87c251309c28fc6",
  measurementId: "G-19B34XZCQP"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);  // Add this export