// components/ProductCard.jsx
import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Chip,
  Skeleton
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CategoryIcon from '@mui/icons-material/Category';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarIcon from "@mui/icons-material/Star";

const ProductCard = ({
  product,
  isMobile = false,
  savedItems = {},
  handleSaveClick,
  category,
  getTimeAgo,
  formatPrice,
  loading = false
}) => {
  return (
    <Card
      component={loading ? 'div' : RouterLink}
      to={loading ? undefined : `/product/${product?.id}`}
      sx={{
        width: '100%',
        borderRadius: 2,
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        cursor: loading ? 'default' : 'pointer',
        textDecoration: 'none',
        border: product?.isPromoted ? '2px solid' : '1px solid',
        borderColor: product?.isPromoted ? 'primary.main' : 'divider',
        backgroundColor: product?.isPromoted ? 'rgba(25, 118, 210, 0.04)' : 'background.paper',
        '&:hover': {
          transform: loading ? 'none' : 'translateY(-4px)',
          boxShadow: loading ? 'none' : '0 4px 12px rgba(0,0,0,0.15)'
        }
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {product?.isPromoted && !loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              left: 8,
              backgroundColor: 'primary.main',
              color: 'white',
              px: 1,
              py: 0.5,
              borderRadius: 1,
              fontSize: '0.75rem',
              fontWeight: 'bold',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <StarIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
            Promoted
          </Box>
        )}

        {loading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={isMobile ? 160 : 200}
            sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          />
        ) : (
          <Box
            component="img"
            src={product.images?.[0] || '/placeholder-product.jpg'}
            alt={product.title}
            loading="lazy"
            sx={{
              width: '100%',
              height: isMobile ? 160 : 200,
              objectFit: 'cover',
              filter: 'blur(10px)',
              transition: 'filter 0.3s ease',
              '&:not([src="/placeholder-product.jpg"])': {
                filter: 'blur(0px)'
              },
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8
            }}
            onLoad={(e) => e.target.style.filter = 'none'}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/placeholder-product.jpg';
            }}
          />
        )}

        {!loading && (
          <IconButton
            onClick={(e) => handleSaveClick(product.id, e)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              backgroundColor: 'rgba(255,255,255,0.8)',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.9)'
              }
            }}
          >
            {savedItems[product.id] ? (
              <FavoriteIcon color="error" />
            ) : (
              <FavoriteBorderIcon color="action" />
            )}
          </IconButton>
        )}

        {!loading && category && (
          <Chip
            label={category.name}
            size="small"
            icon={<CategoryIcon fontSize="small" />}
            sx={{
              position: 'absolute',
              bottom: 8,
              left: 8,
              backgroundColor: 'rgba(255,255,255,0.9)',
              fontWeight: 'medium'
            }}
          />
        )}
      </Box>

      <CardContent sx={{ p: isMobile ? 1.5 : 2 }}>
        {loading ? (
          <>
            <Skeleton width="80%" height={24} />
            <Skeleton width="40%" height={20} />
            <Skeleton width="60%" height={20} sx={{ mt: 1.5 }} />
            <Skeleton width="90%" height={20} sx={{ mt: 1.5 }} />
          </>
        ) : (
          <>
            <Typography
              component="h7"
              sx={{
                fontWeight: 'bold',
                fontSize: isMobile ? '0.9rem' : '1rem',
                mb: 0.5,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'text.primary'
              }}
            >
              {product.title}
            </Typography>

            <Typography
              variant="h7"
              color="primary"
              sx={{ fontWeight: 'bold', mb: 0.5 }}
            >
              {formatPrice(product.price)}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1.5, mb: 2 }}>
              <LocationOnIcon color="primary" fontSize="small" />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                {product.location || 'Location not specified'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption" color="text.secondary">
                {getTimeAgo(product.createdAt)}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {product.seller?.verified && (
                  <VerifiedUserIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
                )}
                <Typography variant="caption" color="text.secondary">
                  {product.seller?.name || 'Seller not specified'}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductCard;
