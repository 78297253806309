import React, { useState, useEffect } from 'react';
import {
    Container, Box, Typography, Button, TextField,
    Paper, Grid, Divider, IconButton, InputAdornment,
    CircularProgress, Alert, Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff, Google, Facebook } from '@mui/icons-material';
import { auth, db } from '../config/firebase';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    getAdditionalUserInfo // Import this for better new user check
} from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore'; // Added serverTimestamp
import { useNavigate, useLocation } from 'react-router-dom'; // <--- Import useNavigate AND useLocation

// Styled components (remain the same)
const AuthContainer = styled(Container)(({ theme }) => ({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
}));

const AuthPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: '16px',
    boxShadow: theme.shadows[10],
    width: '100%',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
    },
}));

const AuthButton = styled(Button)(({ theme }) => ({
    borderRadius: '12px',
    padding: '12px 24px',
    fontWeight: 600,
    textTransform: 'none',
    marginTop: theme.spacing(2),
    width: '100%',
}));

const SocialButton = styled(Button)(({ theme }) => ({
    borderRadius: '12px',
    padding: '10px',
    fontWeight: 500,
    textTransform: 'none',
    width: '100%',
    margin: theme.spacing(1, 0),
}));

const AuthForm = styled('form')({
    width: '100%',
});
// --- End of Styled Components ---

const Auth = () => {
    // Default to login, useEffect will adjust if on /signup path
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null); // Kept as per user code
    const navigate = useNavigate();
    const locationHook = useLocation(); // Use the hook here

    // Determine initial mode based on path
    useEffect(() => {
         // Use the location object from the hook
         if (locationHook.pathname === '/signup') {
             setIsLogin(false);
         } else {
             setIsLogin(true);
         }
         // Clear fields and errors when path changes (or component mounts)
         setEmail('');
         setPassword('');
         setName('');
         setError(null);
         setSuccess(null);
    }, [locationHook.pathname]); // Rerun effect if pathname changes


    const toggleAuthMode = () => {
        // Instead of just toggling, navigate to the other route
        // This keeps the URL and component state consistent
        if (isLogin) {
            navigate('/signup');
        } else {
            navigate('/login');
        }
        // The useEffect will handle setting isLogin state based on the new path
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null); // Clear previous success message

        try {
            if (isLogin) {
                // --- Sign in ---
                await signInWithEmailAndPassword(auth, email, password);
                setSuccess('Successfully signed in!'); // Set success message
                // Redirect AFTER showing success (optional)
                setTimeout(() => navigate('/home'), 1000); // Redirect after 1 sec
                // Or redirect immediately: navigate('/home');

            } else {
                // --- Sign up ---
                if (!name) { // Basic validation
                    setError("Please enter your full name.");
                    setLoading(false);
                    return;
                }
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Save additional user info to Firestore
                await setDoc(doc(db, 'users', user.uid), {
                    uid: user.uid,
                    name: name,
                    email: user.email,
                    createdAt: serverTimestamp(),
                    lastLogin: serverTimestamp(),
                    photoURL: user.photoURL || null
                });

                setSuccess('Account created successfully!'); // Set success message
                 // Redirect AFTER showing success (optional)
                setTimeout(() => navigate('/home'), 1000); // Redirect after 1 sec
                // Or redirect immediately: navigate('/home');
            }
        } catch (err) {
            console.error("Auth Error:", err);
            if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
              setError('Invalid email or password.');
            } else if (err.code === 'auth/email-already-in-use') {
              setError('This email address is already registered.');
            } else if (err.code === 'auth/weak-password') {
              setError('Password should be at least 6 characters long.');
            } else {
              setError(err.message || 'An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSocialLogin = async (providerName) => {
        setLoading(true);
        setError(null);
        setSuccess(null); // Clear previous success message

        let authProvider;
        if (providerName === 'google') {
            authProvider = new GoogleAuthProvider();
        } else if (providerName === 'facebook') {
            authProvider = new FacebookAuthProvider();
        } else {
             setError("Invalid social provider");
             setLoading(false);
             return;
        }

        try {
            const result = await signInWithPopup(auth, authProvider);
            const user = result.user;
            const additionalInfo = getAdditionalUserInfo(result);

            if (additionalInfo?.isNewUser) {
                console.log("New user detected via social login:", user.email);
                await setDoc(doc(db, 'users', user.uid), {
                    uid: user.uid,
                    name: user.displayName || 'Social User',
                    email: user.email,
                    photoURL: user.photoURL || null,
                    createdAt: serverTimestamp(),
                    lastLogin: serverTimestamp(),
                    provider: additionalInfo.providerId
                });
            } else {
                 console.log("Existing user logged in via social login:", user.email);
                 // Optionally update lastLogin for existing users
                 // await updateDoc(doc(db, 'users', user.uid), { lastLogin: serverTimestamp() });
            }

            setSuccess('Successfully signed in!'); // Set success message
             // Redirect AFTER showing success (optional)
            setTimeout(() => navigate('/home'), 1000); // Redirect after 1 sec
            // Or redirect immediately: navigate('/home');

        } catch (err) {
             console.error("Social Auth Error:", err);
             if (err.code === 'auth/account-exists-with-different-credential') {
                setError('An account already exists with this email address using a different sign-in method.');
             } else if (err.code === 'auth/popup-closed-by-user') {
                setError('Sign-in popup closed before completion.');
             } else if (err.code === 'auth/cancelled-popup-request') {
                 // Ignore quietly
             } else {
                 setError(err.message || 'Failed to sign in with social account.');
             }
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(null);
        setSuccess(null); // Also close success snackbar
    };

    // Render logic remains the same...
    return (
        <AuthContainer maxWidth="sm">
            <AuthPaper elevation={3}>
                <Box textAlign="center" mb={4}>
                    <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
                        {isLogin ? 'Welcome Back' : 'Create Account'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {isLogin ? 'Sign in to continue' : 'Join us today'}
                    </Typography>
                </Box>

                <AuthForm onSubmit={handleSubmit} noValidate>
                    {!isLogin && (
                        <TextField
                            fullWidth
                            label="Full Name"
                            variant="outlined"
                            margin="normal"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required={!isLogin}
                            autoComplete="name"
                            sx={{ mb: 2 }}
                        />
                    )}

                    <TextField
                        fullWidth
                        label="Email Address"
                        variant="outlined"
                        margin="normal"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete={isLogin ? "current-password" : "new-password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ mb: 3 }}
                    />

                    <AuthButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        size="large"
                    >
                        {loading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : isLogin ? (
                            'Sign In'
                        ) : (
                            'Sign Up'
                        )}
                    </AuthButton>
                </AuthForm>

                <Box textAlign="center" my={3}>
                    <Divider>
                        <Typography variant="body2" color="text.secondary" sx={{ px: 1 }}>
                            OR
                        </Typography>
                    </Divider>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <SocialButton
                            variant="outlined"
                            startIcon={<Google />}
                            onClick={() => handleSocialLogin('google')}
                            disabled={loading}
                        >
                            Sign in with Google
                        </SocialButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SocialButton
                            variant="outlined"
                            startIcon={<Facebook />}
                            onClick={() => handleSocialLogin('facebook')}
                            disabled={loading}
                        >
                           Sign in with Facebook
                        </SocialButton>
                    </Grid>
                </Grid>

                <Box textAlign="center" mt={3}>
                    <Typography variant="body2">
                        {isLogin ? "Don't have an account? " : 'Already have an account? '}
                        <Button
                            color="primary"
                            onClick={toggleAuthMode} // Use navigate toggle
                            sx={{ textTransform: 'none', fontWeight: 600 }}
                        >
                            {isLogin ? 'Sign up now' : 'Sign in'}
                        </Button>
                    </Typography>
                </Box>

                {/* Snackbar for Errors */}
                <Snackbar
                     open={!!error}
                     autoHideDuration={6000}
                     onClose={handleCloseSnackbar}
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {error}
                    </Alert>
                </Snackbar>

                {/* Snackbar for Success (Kept as requested) */}
                <Snackbar
                    open={!!success}
                    autoHideDuration={3000} // Shorter duration for success
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {success}
                    </Alert>
                </Snackbar>

            </AuthPaper>
        </AuthContainer>
    );
};

export default Auth;